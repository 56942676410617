import React, { useState, useEffect } from "react";
// import './CustomComponent.css'; // Import the custom CSS file for the component
// import { IoMdCreate, IoMdTrash } from 'react-icons/io';
import { IoIosAddCircleOutline } from "react-icons/io";
import { MdEdit, MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import {
  EditEducation,
  EducationpointerFlagpointer,
  Educationstore,
  Progress,
} from "../Redux/counterSlice";
import TopNav from "./TopNav";
import { makeApiRequest } from "./Services";

const Education = () => {
  const [educationsdata, setEducationsdata] = useState([]);

  const education = useSelector((state) => state.counter.addeducation);
  const Educationpointer = useSelector(
    (state) => state.counter.Educationpointer
  );
  const Contactdata = useSelector((state) => state.counter.initialState);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleNavigate = (route) => {
    if(education.length === 3 || education.length > 3)
    {
      toast.error("You can not add more than 3 educations");
    }
    else{

      navigate(route);
    }
  };
  const handlereditcase = (event) => {
    
    const getid = event;
    dispatch(EditEducation(getid));
    navigate("/AddEdu");
  };
  useEffect(() => {
    
    if (education) {
      const educationCopy = education.map((exp) => ({
        ...exp,
        graduatemonth:
          exp.graduatemonth ,
        graduateyear:
          exp.graduateyear
      }));
      setEducationsdata(educationCopy);
    }
  }, [education]);

  useEffect(() => {
    // Perform navigation here
    navigate("/Education"); // Replace '/destination' with the actual path you want to navigate to
  }, []);

  const DeleteRow = (id) => {
    
    const dataCopy = education.map((value) => {
      if (value.id === id) {
        return { ...value, activity: "delete" };
      }
      return value;
    });
    const filteredData = dataCopy.filter((val) => val.activity !== "delete");
    dispatch(Educationstore(filteredData));
    const requestBody = {
      resume_id: Contactdata.resume_id ? Contactdata.resume_id : 0 ,
      methodId: 'education',
      edu_id: +id
    };
    makeApiRequest(requestBody)
  };

  const HandleNextStep = () => {
    
    // if(education.length)
    // {
    if (!Educationpointer) {
      dispatch(Progress(36));
    }
    // dispatch(Progress(24));
    dispatch(EducationpointerFlagpointer(true));

    navigate("/certification");
    // }
    // else{
    //   toast.error("Add Education");
    // }
  };
  return (
    <div className="col-lg-10 p-0 rightSideSection">
      <ToastContainer />

      <TopNav MainHeading={"Add your most relevant education *Tip: we recommend 3 or less"} />
      <div className="componentContainer paddingGen shadow">
      <div className="row mt-3 mb-3 ">
          <div className="col-lg-12">
            <h2 className="headingmain mt-4 mb-4">Add your most relevant education *Tip: We recommend the three or less</h2>
          </div>
        </div>
        <div
          className=" border border-dark mb-5 pl-1 pr-1 "
          style={{
            borderRadius: "10px",
            borderColor: "rgb(126, 125, 130)",
          }}
        >
          {educationsdata.map((val) => (
            <div className="row " id={val.id} key={val.id}>
              <div className="justify-content-center ">
                <div className="p-2 pb-0">
                  <div className="card mb-0">
                    <div className="card-body">
                      <div className="row ">
                        <div className="col-md-6 text-right">
                          <h4 className="card-title text-sm-start">{`${
                            val.fieldOfStudy
                          } - ${val.graduatemonth} - ${
                            val.studyContinue ? "In Progress" : `${"to-"}${val.graduateyear} `
                          }`}</h4>
                        </div>
                        <div className="col-md-6 text-lg-end">
                          <MdEdit
                            className="mr-2 edit-icon"
                            size={24}
                            id={val.id}
                            key={val.id}
                            onClick={() => handlereditcase(val.id)}
                          />
                          <MdDelete
                            size={24}
                            onClick={() => DeleteRow(val.id)}
                            className="delete-icon"
                          />
                        </div>
                      </div>
                      <div className="row mt-1">
                        <div className="col-md-12">
                          <p className="card-text text-sm-start">
                            {`${val.summary}`}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="row">
            <div className="justify-content-center ">
              <div className="p-2">
                <div className="card">
                  <div className="card-body">
                    <div className="row ">
                      <button
                        type="button"
                        class="btn btn-outline-ligh"
                        onClick={() => handleNavigate("/AddEdu")}
                      >
                        <IoIosAddCircleOutline size={24} /> Add Education
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end my-5">
          <button
            class="btn btn-secondary bg-white  w-25 me-3 btn-back"
            onClick={() => handleNavigate("/WorkExperience")}
          >
            Back
          </button>
          <button class="btn w-25 btn-delete" onClick={HandleNextStep}>
            {`    `}Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Education;
