import React, { useState, useRef } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { AdminCredID } from "../Redux/counterSlice";
import { useSelector, useDispatch } from 'react-redux';

const AdminLogin = () => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const usernameRef = useRef(null);
  const passwordRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleInputChange = (event) => {
    
    const { id, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };
  const handleNavigate = (route) => {
    navigate(route);
  };
  const handleSubmit = async (event) => {
    
    event.preventDefault();

    const data = {
      admin_email: usernameRef.current.value,
      admin_password: passwordRef.current.value,
    };
    const Jsonedata = JSON.stringify(data);
    try {
      
      const response = await axios.post(
        "https://cloudresumes.com/api/admin_login.php",
        Jsonedata,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        
        if (response.data) {
          if (response.data.msg === "Admin authenticated") {
            toast.success(response.data.msg);
            data.admin_token = response.data.token;
            dispatch(AdminCredID(data))
            setTimeout(() => {
              handleNavigate("/Profile");
            }, 1000);
            
          }
          else{
            toast.warning(response.data.msg);
          }
        }
      } else {
        toast.error(response.data.msg);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <ToastContainer />
      {/* Centered Logo */}
      <div className="text-center col-md-4 loginContainer">
        <div className="mx-3 side-menu-logo">
        <img
            src="./logo.png"
            alt="Logo"
            className="mb-3"
            style={{ maxWidth: "45%" }}
          />
        </div>
        <div className="card mx-3 cards">
          <div className="card-body">
            <h5 className="card-title text-center mb-4">Admin Login</h5>
            <div className="form-group mb-4">
              <input
                type="text"
                className="form-control"
                id="username"
                placeholder="Email"
                ref={usernameRef}
                value={formData.username}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group mb-4">
              <input
                type="password"
                className="form-control"
                id="password"
                placeholder="Password"
                ref={passwordRef}
                value={formData.password}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group mb-4">
              <button
                type="submit"
                className="btn btn-primary btn-block w-100 mt-5"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
