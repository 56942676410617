// import React, { useEffect } from "react";
import React, { useState, useEffect, useRef } from "react";
// import './CustomComponent.css'; // Import the custom CSS file for the component
// import { IoMdCreate, IoMdTrash } from 'react-icons/io';
import { IoIosAddCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  CareerObjectivestore,
  Progress,
  FinalizedPointer,
  ShowCV1,
  ShowCV2,
  ShowCV3,
  ShowCV4,
  ShowCV5,
  ShowCV6,
  ShowCV7,
  ShowCV8,
  setWaterMark,
} from "../Redux/counterSlice";

import TopNav from "./TopNav";

const Templates = () => {
  const [careerObjective, setCareerObjective] = useState("");
  const careerObjectiveRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const FinalizFlag = useSelector((state) => state.counter.FinalizePointerFlag);
  const UserCreddata = useSelector((state) => state.counter.UserCred);

  const AddCareerObjectiv = () => {
    
    dispatch(CareerObjectivestore(careerObjective));
  };
  const handleObjectiveChange = () => {
    const newObjective = careerObjectiveRef.current.value;
    setCareerObjective(newObjective);
  };
  const handleNavigate = (route) => {
    navigate(route);
  };
  useEffect(() => {

    navigate("/Templates"); 
  }, []);
  const HandleNextStep = () => {
    
    // if(addReferences.length)
    // {
    //   dispatch(TemplatespointerFlagpointer(true));
    //   dispatch(Progress(100));

    //   handleNavigate('/Templates')
    // }
    // else{
    //   toast.error("Add References");
    // }
  };

  const GetImageValue = (event) => {
    debugger;
    switch (event) {
      case "cv1":
        dispatch(ShowCV1(true)); 
        localStorage.setItem("ShowCV1", JSON.stringify(true));
        localStorage.removeItem("ShowCV2");
        localStorage.removeItem("ShowCV3");
        localStorage.removeItem("ShowCV4");
        localStorage.removeItem("ShowCV5");
        localStorage.removeItem("ShowCV6");
        localStorage.removeItem("ShowCV7");
        localStorage.removeItem("ShowCV8");
        dispatch(ShowCV2(false)); 
        dispatch(ShowCV3(false)); 
        dispatch(ShowCV4(false));
        dispatch(ShowCV5(false));
        dispatch(ShowCV6(false));
        dispatch(ShowCV7(false));
        dispatch(ShowCV8(false)); 
        dispatch(FinalizedPointer(true));
        dispatch(Progress(100));
        navigate("/Finalized");
        break;

      case "cv2":
        dispatch(ShowCV2(true)); 
        localStorage.setItem("ShowCV2", JSON.stringify(true));
        localStorage.removeItem("ShowCV1");
        localStorage.removeItem("ShowCV3");
        localStorage.removeItem("ShowCV4");
        localStorage.removeItem("ShowCV5");
        localStorage.removeItem("ShowCV6");
        localStorage.removeItem("ShowCV7");
        localStorage.removeItem("ShowCV8");
        dispatch(ShowCV1(false)); 
        dispatch(ShowCV3(false)); 
        dispatch(ShowCV4(false));
        dispatch(ShowCV8(false));
        dispatch(ShowCV5(false));
        dispatch(ShowCV6(false));
        dispatch(ShowCV7(false));
        dispatch(FinalizedPointer(true));
        dispatch(Progress(100));

        navigate("/Finalized");

        break;

      case "cv3":
        dispatch(ShowCV2(false)); 
        dispatch(ShowCV1(false));
        dispatch(ShowCV8(false));
        dispatch(ShowCV3(true));
        localStorage.setItem("ShowCV3", JSON.stringify(true));
        localStorage.removeItem("ShowCV2");
        localStorage.removeItem("ShowCV1");
        localStorage.removeItem("ShowCV4");
        localStorage.removeItem("ShowCV5");
        localStorage.removeItem("ShowCV6");
        localStorage.removeItem("ShowCV7");
        localStorage.removeItem("ShowCV8");
        dispatch(ShowCV4(false));
        dispatch(ShowCV5(false));
        dispatch(ShowCV5(false));
        dispatch(ShowCV6(false))
        dispatch(ShowCV7(false));;
        dispatch(FinalizedPointer(true));
        dispatch(Progress(100));

        navigate("/Finalized");

        break;
      case "cv4":
        dispatch(ShowCV2(false)); 
        dispatch(ShowCV1(false));
        dispatch(ShowCV3(false));
        dispatch(ShowCV8(false));
        dispatch(ShowCV4(true)); 
        localStorage.setItem("ShowCV4", JSON.stringify(true));
        localStorage.removeItem("ShowCV2");
        localStorage.removeItem("ShowCV3");
        localStorage.removeItem("ShowCV1");
        localStorage.removeItem("ShowCV5");
        localStorage.removeItem("ShowCV6");
        localStorage.removeItem("ShowCV7");
        localStorage.removeItem("ShowCV8");
        dispatch(ShowCV5(false));
        dispatch(ShowCV7(false));
        dispatch(ShowCV6(false));
        dispatch(FinalizedPointer(true));
        dispatch(Progress(100));

        navigate("/Finalized");

        break;

      case "cv5":
        dispatch(ShowCV2(false)); 
        dispatch(ShowCV1(false));
        dispatch(ShowCV3(false));
        dispatch(ShowCV8(false));
        dispatch(ShowCV4(false));
        dispatch(ShowCV5(true));
        localStorage.setItem("ShowCV5", JSON.stringify(true));
        localStorage.removeItem("ShowCV2");
        localStorage.removeItem("ShowCV3");
        localStorage.removeItem("ShowCV4");
        localStorage.removeItem("ShowCV1");
        localStorage.removeItem("ShowCV6");
        localStorage.removeItem("ShowCV7");
        localStorage.removeItem("ShowCV8");
        dispatch(ShowCV6(false));
        dispatch(ShowCV7(false));
        // dispatch(ShowCV5(true)); 
        dispatch(FinalizedPointer(true));
        dispatch(Progress(100));

        navigate("/Finalized");
        break;

      case "cv6":
        dispatch(ShowCV2(false)); 
        dispatch(ShowCV1(false));
        dispatch(ShowCV3(false));
        dispatch(ShowCV4(false));
        dispatch(ShowCV5(false));
        dispatch(ShowCV8(false));
        dispatch(ShowCV6(true)); 
        localStorage.setItem("ShowCV6", JSON.stringify(true));
        localStorage.removeItem("ShowCV2");
        localStorage.removeItem("ShowCV3");
        localStorage.removeItem("ShowCV4");
        localStorage.removeItem("ShowCV5");
        localStorage.removeItem("ShowCV1");
        localStorage.removeItem("ShowCV7");
        localStorage.removeItem("ShowCV8");
        dispatch(ShowCV7(false)); 
        dispatch(FinalizedPointer(true));
        dispatch(Progress(100));
        navigate("/Finalized");
        break;
      case "cv7":
        dispatch(ShowCV2(false)); 
        dispatch(ShowCV1(false));
        dispatch(ShowCV3(false));
        dispatch(ShowCV4(false));
        dispatch(ShowCV5(false));
        dispatch(ShowCV6(false));
        dispatch(ShowCV8(false));
        // dispatch(ShowCV5(false)); 
        dispatch(ShowCV7(true)); 
        localStorage.setItem("ShowCV7", JSON.stringify(true));
        localStorage.removeItem("ShowCV2");
        localStorage.removeItem("ShowCV3");
        localStorage.removeItem("ShowCV4");
        localStorage.removeItem("ShowCV5");
        localStorage.removeItem("ShowCV6");
        localStorage.removeItem("ShowCV1");
        localStorage.removeItem("ShowCV8");
        dispatch(FinalizedPointer(true));
        dispatch(Progress(100));
        navigate("/Finalized");
        
        break;

      case "cv8":
        
        dispatch(ShowCV2(false)); 
        dispatch(ShowCV1(false));
        dispatch(ShowCV3(false));
        dispatch(ShowCV4(false));
        dispatch(ShowCV5(false));
        dispatch(ShowCV6(false));
        // dispatch(ShowCV5(false));
        dispatch(ShowCV7(false));
        dispatch(ShowCV8(true)); 
        localStorage.setItem("ShowCV8", JSON.stringify(true));
        localStorage.removeItem("ShowCV2");
        localStorage.removeItem("ShowCV3");
        localStorage.removeItem("ShowCV4");
        localStorage.removeItem("ShowCV5");
        localStorage.removeItem("ShowCV6");
        localStorage.removeItem("ShowCV7");
        localStorage.removeItem("ShowCV1");
        dispatch(FinalizedPointer(true));
        dispatch(Progress(100));
        navigate("/Finalized");
        // dispatch(actionType3(payload3)); // Dispatch the action associated with condition3
        break;

      default:
      // Handle the default case if needed
    }
    if (Object.keys(UserCreddata).length !== 0) {
      if(UserCreddata.subscription !== undefined && UserCreddata.subscription !== 'none')
      {
        dispatch(setWaterMark(true))
      }
    }
    // handleNavigate('/Finalized')
  };
  return (
    <div className="col-lg-10 p-0 rightSideSection">
      <TopNav MainHeading={"Templates"} />
      <div className="componentContainer paddingGen">
        <div
          className="border border-dark shadow"
          style={{
            borderRadius: "10px",
            borderColor: "rgb(126, 125, 130)",
          }}
        >
          <div class="image-row p-2">
            <div class="image">
              <a
                // href="http://localhost:3000/blacktempCv"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="./cv1.png"
                  alt="Image 1"
                  className="w-100"
                  style={{
                    borderRadius: "8px",
                    transition: "border-color 0.3s", // Adding a smooth transition effect
                    border: "2px solid transparent", // Initial border style
                  }}
                  onClick={() => GetImageValue("cv1")}
                  onMouseOver={(e) => {
                    e.target.style.borderColor = "blue"; // Change border color on hover
                  }}
                  onMouseOut={(e) => {
                    e.target.style.borderColor = "transparent"; // Reset border color on mouse out
                  }}
                />
              </a>
            </div>
            <div class="image">
              <a
                // href="http://localhost:3000/Templates1.png"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="./cv2.png"
                  alt="Image 2"
                  className="w-100"
                  style={{
                    borderRadius: "8px",
                    transition: "border-color 0.3s", // Adding a smooth transition effect
                    border: "2px solid transparent", // Initial border style
                  }}
                  onClick={() => GetImageValue("cv2")}
                  onMouseOver={(e) => {
                    e.target.style.borderColor = "blue"; // Change border color on hover
                  }}
                  onMouseOut={(e) => {
                    e.target.style.borderColor = "transparent"; // Reset border color on mouse out
                  }}
                />
              </a>
            </div>
            <div class="image">
              <a
                // href="http://localhost:3000/Templates1.png"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="./cv3.png"
                  alt="Image 3"
                  className="w-100"
                  style={{
                    borderRadius: "8px",
                    transition: "border-color 0.3s", // Adding a smooth transition effect
                    border: "2px solid transparent", // Initial border style
                  }}
                  onClick={() => GetImageValue("cv3")}
                  onMouseOver={(e) => {
                    e.target.style.borderColor = "blue"; // Change border color on hover
                  }}
                  onMouseOut={(e) => {
                    e.target.style.borderColor = "transparent"; // Reset border color on mouse out
                  }}
                />
              </a>
            </div>
            <div class="image">
              <a
                // href="http://localhost:3000/Templates1.png"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="./cv4.png"
                  alt="Image 4"
                  className="w-100"
                  style={{
                    borderRadius: "8px",
                    transition: "border-color 0.3s", // Adding a smooth transition effect
                    border: "2px solid transparent", // Initial border style
                  }}
                  onClick={() => GetImageValue("cv4")}
                  onMouseOver={(e) => {
                    e.target.style.borderColor = "blue"; // Change border color on hover
                  }}
                  onMouseOut={(e) => {
                    e.target.style.borderColor = "transparent"; // Reset border color on mouse out
                  }}
                />
              </a>
            </div>
            <div class="image">
              <a
                // href="http://localhost:3000/Templates1.png"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="./cv5.png"
                  alt="Image 1"
                  className="w-100"
                  style={{
                    borderRadius: "8px",
                    transition: "border-color 0.3s", // Adding a smooth transition effect
                    border: "2px solid transparent", // Initial border style
                  }}
                  onClick={() => GetImageValue("cv5")}
                  onMouseOver={(e) => {
                    e.target.style.borderColor = "blue"; // Change border color on hover
                  }}
                  onMouseOut={(e) => {
                    e.target.style.borderColor = "transparent"; // Reset border color on mouse out
                  }}
                />
              </a>
            </div>
            <div class="image" onClick={()=>GetImageValue("cv7")}>
              <a
                // href="http://localhost:3000/Templates1.png"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="./cv6.png"
                  alt="Image 2"
                  className="w-100"
                  style={{
                    borderRadius: "8px",
                    transition: "border-color 0.3s", // Adding a smooth transition effect
                    border: "2px solid transparent", // Initial border style
                  }}
                  onMouseOver={(e) => {
                    e.target.style.borderColor = "blue"; // Change border color on hover
                  }}
                  onMouseOut={(e) => {
                    e.target.style.borderColor = "transparent"; // Reset border color on mouse out
                  }}
                />
              </a>
            </div>
            <div class="image" onClick={()=>GetImageValue("cv6")}>
              <a
                // href="http://localhost:3000/Templates1.png"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="./cv7.png"
                  alt="Image 3"
                  className="w-100"
                  style={{
                    borderRadius: "8px",
                    transition: "border-color 0.3s", // Adding a smooth transition effect
                    border: "2px solid transparent", // Initial border style
                  }}
                  onMouseOver={(e) => {
                    e.target.style.borderColor = "blue"; // Change border color on hover
                  }}
                  onMouseOut={(e) => {
                    e.target.style.borderColor = "transparent"; // Reset border color on mouse out
                  }}
                />
              </a>
            </div>
            <div class="image" onClick={()=>GetImageValue("cv8")}>
              <a
                // href="http://localhost:3000/Templates1.png"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="./cv8.png"
                  alt="Image 4"
                  className="w-100"
                  style={{
                    borderRadius: "8px",
                    transition: "border-color 0.3s", // Adding a smooth transition effect
                    border: "2px solid transparent", // Initial border style
                  }}
                  onMouseOver={(e) => {
                    e.target.style.borderColor = "blue"; // Change border color on hover
                  }}
                  onMouseOut={(e) => {
                    e.target.style.borderColor = "transparent"; // Reset border color on mouse out
                  }}
                />
              </a>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end my-5">
          <button
            class="btn btn-back bg-white  me-3 w-25"
            onClick={() => handleNavigate("/References")}
          >
            Back
          </button>
          <button class="btn btn-delete w-25" onClick={HandleNextStep}>
            {`    `}Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Templates;
