import React, { useState, useEffect, useRef } from "react";
import TopNav from "../Components/TopNav";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { AddExperience } from "../Redux/counterSlice";
import { EditExperience } from "../Redux/counterSlice";
import DatePicker from "react-datepicker";
import { ToastContainer, toast } from "react-toastify";

// import 'bootstrap/dist/css/bootstrap.min.css';

const AddNewExp = () => {
  const [formId, setFormId] = useState("");
  const [companyname, setCompanyName] = useState("");
  const [startMonth, setStartMonth] = useState(null);
  const [endYear, setEndYear] = useState(null);
  const [summary, setSummary] = useState("");
  const [currentlyWorking, setCurrentlyWorking] = useState(false);

  // Redux data
  const count = useSelector((state) => state.counter.addexp);
  const Getideditcasee_xp = useSelector((state) => state.counter.editcasee_xp);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleNavigate = (route) => {
    navigate(route);
    dispatch(EditExperience(false));
  };

  useEffect(() => {
    
    if (Getideditcasee_xp) {
      const countcopy = [...count];
      const findexpIndex = count.findIndex((x) => x.id === Getideditcasee_xp);
      if(findexpIndex !== -1)
      {
      setCompanyName(countcopy[findexpIndex].companyname);
      setStartMonth(countcopy[findexpIndex].startMonth);
      setEndYear(countcopy[findexpIndex].endYear);
      setSummary(countcopy[findexpIndex].summary);
      setCurrentlyWorking(countcopy[findexpIndex].currentlyWorking);
      setFormId(countcopy[findexpIndex].id);
      }
    }
  }, [Getideditcasee_xp]);

  function generateRandomId(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomId = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomId += characters.charAt(randomIndex);
    }

    return randomId;
  }

  const CheckValidField = () => {
    if (companyname === undefined || companyname.trim() === "") {
      toast.error("Company Name Cannot Be Empty");
      return false;
    }

    if (!startMonth) {
      toast.error("Start Date Cannot Be Empty");
      return false;
    }

    if (!currentlyWorking && !endYear) {
      toast.error("End Date Cannot Be Empty");
      return false;
    }

    return true;
  };

  const saveExpRecords = () => {
 
    let array = [];
    const randomId = generateRandomId(companyname.length);
    if (count) {
      array = [...count];
    }
    const checkEditCase = array.findIndex((x) => x.id === formId);
    if (checkEditCase === -1) {
      const obj = {
        id: randomId,
        companyname: companyname,
        startMonth: startMonth,
        endYear: endYear,
        summary: summary,
        currentlyWorking: currentlyWorking,
      };
      array.push(obj);
      dispatch(AddExperience(array)); 
      handleNavigate("/WorkExperience");
    } else {
      const obj = {
        id: formId,
        companyname: companyname,
        startMonth: startMonth,
        endYear: endYear,
        summary: summary,
        currentlyWorking: currentlyWorking,
      };
      array[checkEditCase] = obj;
      dispatch(AddExperience(array));
      handleNavigate("/WorkExperience");
    }
    // }
  };

  function formatDate(inputDate) {
    const dateObject = new Date(inputDate);
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(dateObject.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}
  const handleChange = (date) => {
    debugger;
    if (date) {
      const inputDate = new Date(date);
      const formattedDate = formatDate(inputDate);
      setStartMonth(formattedDate);
      console.log(date); 
    } else {
      setStartMonth(null);
    }
  };
  const handleChangeendYear = (date) => {
    
    if (date) {
      const inputDates = new Date(date);
      const formattedDates = formatDate(inputDates);
      setEndYear(formattedDates);
    } else {
      setEndYear(null);
    }
  };
const experianceHandle =(value)=>{
  debugger;
  if(value.length < 250)
  {
    setSummary(value)
  }

}
  return (
    <div className="col-lg-10 p-0 rightSideSection">
      <ToastContainer />
      <TopNav MainHeading={"Add Experience"} />

      {/* Company Name */}
      <div className="componentContainer paddingGen shadow form-font">
        <div className="row mb-3">
          <div className="col-md-6 ">
            <label htmlFor="companyname" className="mb-1">
              Company Name
            </label>
            <input
              type="text"
              className="form-control inputfield"
              id="companyname"
              placeholder="Company Name"
              value={companyname}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </div>
          <div className="col-6">
            <label className="mb-1" htmlFor="summary">
              Summary (Optional)
            </label>
            <textarea
              className="form-control inputfield h-100"
              id="summary"
              placeholder="Summary"
              value={summary}
              onChange={(e) => experianceHandle(e.target.value)}
            ></textarea>
            <p style={{fontSize: '14px', fontStyle: 'italic'}}>Include short summary of position, detailing any notable achievements within the role.</p>
          </div>
        
        </div>

        {/* Summary */}
        <div className="row mb-5">
        
        <div className="col-6 mt-3 mt-lg-0 col-md-6">
            <label htmlFor="startdate" className="mb-1">
              Start Date
            </label>
            <br />
            <DatePicker
              // selected={startMonth != null ? startMonth : ""}
              showYearDropdown
              minDate={new Date(1950, 0, 1)}
              maxDate={new Date()}
              yearDropdownItemNumber={100}
              scrollableYearDropdown
              onChange={handleChange}
              value={startMonth}
              dateFormat="dd-MM-yyyy"
              showTimeSelect={false}
              className="form-control inputfield"
              id="startdate"
              placeholderText="Start Date"
            />
          </div>
       


        </div>

        {/* Currently Working Checkbox */}
        <div className="row mb-3">
           {!currentlyWorking && (
            <div className="col-6 mt-3 mt-lg-0 col-md-6">
              <label htmlFor="endYear" className="mb-1">
                End Date
              </label>
              <br />
              <DatePicker
                // selected={endYear != null ? endYear : ""}
                showYearDropdown
                minDate={new Date(1950, 0, 1)}
                maxDate={new Date()}
                yearDropdownItemNumber={100}
                scrollableYearDropdown
                value={endYear}
                onChange={handleChangeendYear}
                dateFormat="dd-MM-yyyy"
                showTimeSelect={false}
                className="form-control inputfield"
                id="endYear"
                placeholderText="End Date"
              />
            </div>
          )}
          <div className="col">
            <div className="form-check text-start mt-5">
              <input
                type="checkbox"
                className="form-check-input"
                id="currentlyWorking"
                checked={currentlyWorking}
                onChange={(e) => setCurrentlyWorking(e.target.checked)}
              />
              <label className="form-check-label" htmlFor="currentlyWorking">
                I Currently Work Here
              </label>
            </div>
          </div>
        </div>

        {/* Buttons */}
        <div className="d-flex justify-content-end my-5">
          <button
            className="btn btn-secondary bg-white w-25 me-3"
            onClick={() => handleNavigate("/WorkExperience")}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary w-25"
            onClick={saveExpRecords}
          >
            Add
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddNewExp;
