import React, { useState, useEffect } from "react";
import TopNav from "./TopNav";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { userdata } from "../Redux/counterSlice";

const Users = () => {
  const AdminCred_id = useSelector((state) => state.counter.AdminCred);
  const [UserLists, setUserLists] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNavigate = (route) => {
    navigate(route);
  };
  const fetchAllUsers = () => {
    
    // Define the URL of the API endpoint
    const apiUrl = "https://cloudresumes.com/api/fetch_all_users.php";

    // Define query parameters
    const params = {
      admin_id: 1,
      admin_token: AdminCred_id.admin_token,
    };

    // Make the GET request using Axios
    return axios
      .get(apiUrl, { params })
      .then((response) => {
        
        // Handle the successful response here
        console.log("Users:", response.data);
        setUserLists(response.data.data);
        dispatch(userdata(response.data.data))
        return response.data; 
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error fetching users", error);
        throw error; 
      });
  };

  useEffect(() => {
    if(AdminCred_id)
    {
      fetchAllUsers();
    }
    else
    {
      handleNavigate('/AdminLogin')
    }
  }, []);

  const [users] = useState([
    {
      id: 1,
      name: "John Doe",
      userId: "jd123",
      email: "john@example.com",
      joiningDate: "2023-01-15",
      subscription: "Weekly",
    },
    {
      id: 2,
      name: "Jane Smith",
      userId: "js456",
      email: "jane@example.com",
      joiningDate: "2023-02-20",
      subscription: "Monthly",
    },
    // Add more user data here
  ]);

  const [subscriptionFilter, setSubscriptionFilter] = useState("All");

  const handleSubscriptionFilterChange = (e) => {
    setSubscriptionFilter(e.target.value);
  };

  

  const filteredUsers =
    subscriptionFilter === "All"
      ? UserLists
      : UserLists.filter((user) => user.subscription === subscriptionFilter);

  return (
    <div className="col-lg-10 p-0 rightSideSection">
      <TopNav MainHeading={"Users"} />
      {/* <div className="form-group">
        <label>Email:</label>
        <input type="email" className="form-control" value={email} onChange={handleEmailChange} />
      </div> */}
      <div>

        <table className="table m-3 mb-5">
          <thead>
            <tr>
              <th>User Name</th>
              <th>User ID</th>
              <th>User Email</th>
              <th>Joining Date</th>
              <th>Subscription</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user) => (
              <tr key={user.user_id}>
                <td>{user.user_name}</td>
                <td>{user.user_id}</td>
                <td>{user.user_email}</td>
                <td>{user.joining_date}</td>
                <td>{user.subscription}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Users;
