import React, { useState } from "react";
import axios from "axios";
import TopNav from "../Components/TopNav";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Tooltip from "./Tooltip";
// import { FaBeer } from "react-icons/fa";
import { FiAlertCircle } from "react-icons/fi";
import {
  GetContactDetails,
  imageupload,
  ContactFlagpointer,
  Progress,
} from "../Redux/counterSlice";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";

const Contact = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    jobTitle: "",
    phone: "",
    country: "",
    city: "",
    state: "",
    zipCode: "",
    email: "",
    link1: "",
    linkLabel: "",
  });
  // const Contactdata = useSelector((state) => state.initialState);
  const Contactdata = useSelector((state) => state.counter.initialState);
  const ContactImage = useSelector((state) => state.counter.image);

  // const images = useSelector((state) => state.image);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const handleInputChange = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setSelectedFile(file);
  };

  const renderInputField = (id, label, placeholder, type) => {
    return (
      <div className="form-group">
        <label htmlFor={id} className="mb-1">
          {label}
        </label>
        <input
          type={type}
          className="form-control inputfield"
          id={id}
          placeholder={`Enter The ${placeholder}`}
          value={formData[id]}
          onChange={(e) => handleInputChange(id, e.target.value)}
          onKeyDown={(e) => {
            if (type === "text" && id === "phone") {
              const key = e.key;
              if (
                !(key >= "0" && key <= "9") &&
                key !== "Backspace" &&
                key !== "Tab"
              ) {
                e.preventDefault();
              }
            }
            if (
              type === "text" &&
              id !== "phone" &&
              placeholder !== "Zip Code"
            ) {
              const key = e.key;
              if (
                !(
                  (key >= "a" && key <= "z") ||
                  (key >= "A" && key <= "Z") ||
                  key === " " ||
                  key === "-"
                )
              ) {
                e.preventDefault();
              }
            }
          }}
        />
      </div>
    );
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        "https://cloudresumes.com/api/contact_detail.php",
        formData
      );
      console.log("Response from API:", response.data);
      // Handle success or update state if needed
    } catch (error) {
      console.error("Error while making API request:", error);
      // Handle error or update state if needed
    }
  };
  async function convertBlobToBase64(blobUrl) {
    const response = await fetch(blobUrl);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }
  const handleImageChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const blobUrl = URL.createObjectURL(selectedFile);
      try {
        const base64String = await convertBlobToBase64(blobUrl);
        setImageSrc(base64String);
      } catch (error) {
        console.error("Error converting blob to base64:", error);
      }
    }
  };
  const handleNavigate = (route) => {
    navigate(route);
  };

  useEffect(() => {
    if (Contactdata) {
      setFormData(Contactdata);
    }
    if (ContactImage) {
      setImageSrc(ContactImage);
    }
  }, [Contactdata]);

  // Drag and drop work
  useEffect(() => {
    dispatch(imageupload(imageSrc));
  }, [imageSrc]);

  const allowDrop = (event) => {
    event.preventDefault();
  };

  const drop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file && file.type.match("image.*")) {
      const reader = new FileReader();
      reader.onload = (e) => {
        console.log(e.target.result);
        setImageSrc(e.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      alert("Please drop an image file.");
    }
  };
  const checkvalidation = () => {
    if (formData.jobTitle === undefined || formData.jobTitle.trim() === "") {
      toast.error("Job Title Cannot Be Empty");
      return false;
    }
    return true;
  };
  const OnNextchange = () => {
    const validation = checkvalidation();
    if (validation) {
      dispatch(Progress(12));
      let newobj = { ...formData };
      dispatch(GetContactDetails(newobj));
      dispatch(ContactFlagpointer(true));
      saveMarketingData();
      handleNavigate("/WorkExperience");
    }
  };

const saveMarketingData = () => {
  let requestData = {
    first_name:formData.firstName,
    last_name:formData.lastName,
    email:formData.email,
    phone_number:formData.phone
  }

  axios.post('https://cloudresumes.com/api/add_marketing_users.php',requestData).then((response) => {
      if(response.status === 200)
      {
        console.log(response.data);
      }
  }).catch((error) => {
    console.log("error",error)
  })
}


  const RemovedImage = () => {
    // debugger
    dispatch(imageupload(null));
    setImageSrc(null);
  };

  return (
    <div className="col-lg-10 p-0 rightSideSection">
      <ToastContainer />
      <TopNav />

      <div className="componentContainer paddingGen bg-row m-4">
        <div className="row shadow">
          <div className="row mt-3 mb-3 ">
            <div className="col-lg-12">
              <h2 className="headingmain mt-4 mb-4">
                Add contact information so employers can contact you *Tip:
                adding a QR code maximises your resume’s success
              </h2>
            </div>
          </div>
          <div className="col-lg-8 ">
            <div className="row   text-start mt-4">
              <div className="shadow-two col-md-6 col-12 col-lg-6">
                {renderInputField(
                  "firstName",
                  "First Name",
                  "First Name",
                  "text"
                )}
              </div>
              <div className="col-md-6 col-12 col-lg-6">
                {renderInputField("lastName", "Last Name", "Last Name", "text")}
              </div>
              <div className="col-md-6 col-12 col-lg-6 mt-3">
                {renderInputField(
                  "jobTitle",
                  "Job Title or Student Status",
                  "Job Title",
                  "text"
                )}
                <Tooltip content="I.e IT Specialist, student, recent graduate">
                  <FiAlertCircle />
                </Tooltip>
              </div>
              <div className="col-md-6 col-12 col-lg-6 mt-3">
                {renderInputField("phone", "Phone", "Phone", "text")}
              </div>
            </div>
            <div className="row text-start mb-3">
              <div className="col-md-6 col-12 col-lg-6">
                {renderInputField("country", "Country", "Country", "text")}
              </div>
              <div className="col-md-6 col-12 col-lg-6">
                {renderInputField("city", "City", "City", "text")}
              </div>
              <div className="col-md-6 col-12 col-lg-6 mt-3">
                {renderInputField("state", "State (Optionl)", "State", "text")}
              </div>
              <div className="col-md-6 col-12 col-lg-6 mt-3">
                {renderInputField(
                  "zipCode",
                  "Zip Code (Optional)",
                  "Zip Code",
                  "text"
                )}
              </div>
            </div>

            <div className="row text-start">
              <div className="col-md-6 col-12 col-lg-6">
                {renderInputField("email", "Email", "Email", "email")}
              </div>
           {/*   <div className="col-md-6 col-12 col-lg-6">
                {renderInputField(
                  "work experience",
                  "Work Experience",
                  "Work Experience",
                  "text"
                )}
                <Tooltip content="Add most relevant experience to the position you are applying to ">
                  <FiAlertCircle />
                </Tooltip>
                </div> */}
            </div>
          </div>

          <div id="drop-area" className="post col-lg-4 col-12  pb-5 pb-lg-0">
            <div
              id="dropArea"
              onDrop={drop}
              onDragOver={allowDrop}
              style={{
                width: "100%",
                height: "120px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                fontSize: "18px",
                cursor: "pointer",
                position: "relative",
                marginTop: "55px",
              }}
            >
              <img
                src={imageSrc ? imageSrc : "/Draganddrop.png"}
                alt={imageSrc ? "Uploaded" : "Logo"}
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  position: "absolute", // Position the image absolutely
                  top: 0, // Place it at the top
                  left: "50%", // Center it horizontally
                  transform: "translateX(-50%)", // Center it horizontally
                }}
              />
            </div>
            <p className="text-secondary qrDescription">
              Add your headshot to display on resume **Optional- but usually
              used for modelling, acting, real-estate industries
            </p>
            <div className="d-flex">
              <input
                type="text"
                className="form-control"
                id="Graduationyear"
                disabled
                placeholder="Upload Image (Optional)"
              />

              <label
                className="btn ms-2"
                style={{ backgroundColor: "lightgray" }}
              >
                Upload
                <input
                  className="form-control"
                  type="file"
                  id="formFile"
                  style={{ display: "none", backgroundColor: "lightgray" }}
                  onChange={handleImageChange}
                />
              </label>
              <button
                className="btn btn-delete w-25 ms-2 d-flex justify-content-center text-align-center align-item-center"
                onClick={RemovedImage}
              >
                Delete
              </button>
            </div>

            {/* <div className="row mt-3"> */}
            {/* <div className="col-12 qr-code"> */}

            <div className="row text-start mt-3 ">
              <div className="qr-code p-3">
                <h1 style={{ fontSize: "20px", paddingTop: "10px" }}>
                  QR CODE GENERATOR
                </h1>
                <p className="text-secondary qrDescription">
                  Enter URL and title and QR code autogenerates to resume. –
                  Connect your Linkedin Facebook, Instagram or other website Via
                  our QR generator
                </p>
                <div className="col-md-12 col-12 col-lg-12">
                  {renderInputField(
                    "link1",
                    "QR Code URL (Optional)",
                    "URL for QR",
                    "url"
                  )}
                  <Tooltip
                    content=" Enter the URL and the QR automatically generates to your resume
              (I.E LinkedIn, Facebook, Website URL)"
                  >
                    <FiAlertCircle />
                  </Tooltip>
                </div>
                <div className="col-md-12 col-12 col-lg-12">
                  {renderInputField(
                    "linkLabel",
                    "QR Code Title (Optional)",
                    "Title for QR Code",
                    "text"
                  )}
                  <Tooltip
                    content="Enter the title to describe the QR code (I.E LinkedIn, Facebook,
              Portfolio etc)"
                  >
                    <FiAlertCircle />
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className=" row">
              <div className=" col-md-12 col-12 col-lg-12">
                <button
                  className="button btn btn-delete w-100"
                  onClick={OnNextchange}
                >
                  Next
                </button>
              </div>
            </div>
            {/* </div> */}

            {/* </div> */}
          </div>
        </div>

        {/* <div className="d-flex justify-content-start mt-5 pb-5 ">
          <button className="btn btn-primary w-25" onClick={OnNextchange}>
            Next
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default Contact;
