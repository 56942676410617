import React, { useState, useEffect } from "react";
// import './CustomComponent.css'; // Import the custom CSS file for the component
// import { IoMdCreate, IoMdTrash } from 'react-icons/io';
import { IoIosAddCircleOutline } from "react-icons/io";
import { MdEdit, MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  EditReferences,
  TemplatespointerFlagpointer,
  Progress,
  Referencesdatas,
} from "../Redux/counterSlice";
import { ToastContainer, toast } from "react-toastify";
import TopNav from "./TopNav";
import { makeApiRequest } from "./Services";

const References = () => {
  const [Referencesdata, setReferencesdata] = useState([]);
  const Contactdata = useSelector((state) => state.counter.initialState);

  const addReferences = useSelector((state) => state.counter.addReferences);
  const Referencespointer = useSelector(
    (state) => state.counter.Referencespointer
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleNavigate = (route) => {
    if(addReferences.length === 3 || addReferences.length > 3)
    {
      toast.error("You can not add more than 3 references");
    }
    else
    {
      navigate(route);
    }
  };
  const handlereditcase = (event) => {
    const getid = event;
    dispatch(EditReferences(getid));
    navigate("/AddReferences");
  };
  useEffect(() => {
    
    if (addReferences) {
      setReferencesdata(addReferences);
    }
  }, [addReferences]);

  useEffect(() => {
    // Perform navigation here
    navigate("/References"); // Replace '/destination' with the actual path you want to navigate to
  }, []);

  const HandleNextStep = () => {
    
    if (addReferences.length) {
      if (!Referencespointer) {
        dispatch(Progress(84));
      }
      dispatch(TemplatespointerFlagpointer(true));
      navigate("/Templates");
    } else {
      navigate("/Templates");
      dispatch(TemplatespointerFlagpointer(true));
      dispatch(Progress(84));
    }
  };
  const DeleteRow = (id) => {
    
    const dataCopy = addReferences.map((value) => {
      if (value.id === id) {
        return { ...value, activity: "delete" };
      }
      return value;
    });
    const filteredData = dataCopy.filter((val) => val.activity !== "delete");
    dispatch(Referencesdatas(filteredData));
    const requestBody = {
      resume_id: Contactdata.resume_id ? Contactdata.resume_id : 0 ,
      methodId: 'references',
      ref_id: +id
    };
    makeApiRequest(requestBody)
  };
  return (
    <div className="col-lg-10 p-0 rightSideSection">
      <TopNav MainHeading={"Include references like previous co-workers/employers *Tip: avoid friends/family"} />
      <ToastContainer />

      <div className="componentContainer paddingGen shadow">
      <div className="row mt-3 mb-3 ">
          <div className="col-lg-12">
            <h2 className="headingmain mt-4 mb-4">Include references like previous co-workers/employers 
*Tip: avoid using friends/family as refrences

</h2>
          </div>
        </div>
        <div
          className="border border-dark"
          style={{
            borderRadius: "10px",
            borderColor: "rgb(126, 125, 130)",
          }}
        >
          {Referencesdata.map((val) => (
            <div className="row" id={val.id} key={val.id}>
              <div className="justify-content-center ">
                <div className="p-2 pb-0">
                  <div className="card">
                    <div className="card-body">
                      <div className="row ">
                        <div className="col-md-6 text-right">
                          <h4 className="card-title text-sm-start">{`${val.FirstName} - ${val.PositionTitle}`}</h4>
                        </div>
                        <div className="col-md-6 text-lg-end">
                          <MdEdit
                            size={24}
                            className="mr-2 edit-icon"
                            id={val.id}
                            key={val.id}
                            onClick={() => handlereditcase(val.id)}
                          />
                          <MdDelete
                            onClick={() => DeleteRow(val.id)}
                            size={24}
                            className="delete-icon"
                          />
                        </div>
                      </div>
                      <div className="row mt-1">
                        <div className="col-md-12">
                          <p className="card-text text-sm-start">
                            {`${val.OrganizationName}`}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="row">
            <div className="justify-content-center ">
              <div className="p-2">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <button
                        type="button"
                        class="btn btn-outline-ligh"
                        onClick={() => handleNavigate("/AddReferences")}
                      >
                        <IoIosAddCircleOutline size={24} /> Add References
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end my-5">
          <button
            class="btn btn-back bg-white  me-3 w-25"
            onClick={() => handleNavigate("/CareerObjective")}
          >
            Back
          </button>
          <button class="btn btn-delete w-25" onClick={HandleNextStep}>
            {`    `}Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default References;
