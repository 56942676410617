import React, { useState, useEffect } from "react";
import TopNav from "../Components/TopNav";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Skillsdata } from "../Redux/counterSlice";
import { EditSkills } from "../Redux/counterSlice";
import { ToastContainer, toast } from "react-toastify";
const AddSkill = () => {
  const [SkillName, setSkillName] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Skills_data = useSelector((state) => state.counter.skillsdata);
  const Editskillsdata_data = useSelector(
    (state) => state.counter.Editskillsdata
  );

  const handleNavigate = (route) => {
    navigate(route);
    dispatch(EditSkills(false));
  };
  useEffect(() => {
   debugger; 
    if (Editskillsdata_data) {
      const getname = Skills_data.filter((x) => x.id === Editskillsdata_data);
      if(getname.length > 0)
      {
        setSkillName(getname[0].SkillName);
      }
    }
    return () => {};
  }, [Editskillsdata_data]);

  function generateRandomId(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomId = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomId += characters.charAt(randomIndex);
    }

    return randomId;
  }

  const CheckValidField = () => {
    if (SkillName === undefined || SkillName.trim() === "") {
      toast.error("Skill Name Cannot Be Empty");
      return false;
    }
    return true;
  };
  const saveSkill = () => {
    if (Editskillsdata_data) {
      const getname = Skills_data.map((x) => {
        if (x.id === Editskillsdata_data) {
          return {
            ...x,
            SkillName: SkillName,
          };
        }
        return x;
      });
      dispatch(Skillsdata(getname));

      dispatch(EditSkills(false));
      handleNavigate("/Skills");
    } else {
      let newarrayskill = [];
      const randomId = generateRandomId(SkillName.length);
      if (Skills_data != undefined) {
        newarrayskill = [...Skills_data];
        const obj = {
          id: randomId,
          SkillName: SkillName,
        };
        newarrayskill.push(obj);
        dispatch(Skillsdata(newarrayskill));
        handleNavigate("/Skills");
      } else {
        newarrayskill = [];
        const obj = {
          id: randomId,
          SkillName: SkillName,
        };
        newarrayskill.push(obj);
        dispatch(Skillsdata(newarrayskill));
        handleNavigate("/Skills");
      }
      // }
    }
  };
  return (
    <div className="col-lg-10 p-0 rightSideSection">
      <ToastContainer />

      <TopNav MainHeading={"Add New Skill"} />
      {/* First row */}
      <div className="componentContainer paddingGen shadow form-font">
        <div className="row text-start">
          <div className="col-lg-4">
            <label className="mb-1" htmlFor="SkillName">
              Skill
            </label>
            <input
              type="text"
              className="form-control inputfield"
              id="skill"
              placeholder="Skill Name"
              value={SkillName}
              onChange={(e) => setSkillName(e.target.value)}
            />
          </div>
        </div>
        <div class="d-flex justify-content-end my-5">
          <button
            class="btn btn-secondary bg-white  me-3 w-25"
            onClick={() => handleNavigate("/Skills")}
          >
            Cancel
          </button>
          <button class="btn btn-primary w-25" onClick={saveSkill}>
            {`    `}Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddSkill;
