import React, { useState, useEffect } from "react";
// import './CustomComponent.css'; // Import the custom CSS file for the component
// import { IoMdCreate, IoMdTrash } from 'react-icons/io';
import AddNewExp from "./AddNewExp";
import { IoIosAddCircleOutline } from "react-icons/io";
import { MdEdit, MdDelete } from "react-icons/md";
import TopNav from "../Components/TopNav";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { EditExperience } from "../Redux/counterSlice";
import { AddExperience } from "../Redux/counterSlice";
import { workpointerFlagpointer } from "../Redux/counterSlice";
import { Progress } from "../Redux/counterSlice";
import { ToastContainer, toast } from "react-toastify";
import { makeApiRequest } from "./Services";

const WorkExperience = () => {
  const [workExp, setWorkExpt] = useState([]);
  const navigate = useNavigate();
  const count = useSelector((state) => state.counter.addexp);
  const workpointer = useSelector((state) => state.counter.workpointer);
  const Contactdata = useSelector((state) => state.counter.initialState);

  const dispatch = useDispatch();

  const handleNavigate = (route) => {
    if(count.length === 4 || count.length > 4)
    {
      toast.error("You can not add more than 4 experience");
    }
    else
    {
      navigate(route);
    }
  };
  const DeleteRow = (id) => {
    
    const dataCopy = workExp.map((value) => {
      if (value.id === id) {
        return { ...value, activity: "delete" };
      }
      return value;
    });
    const filteredData = dataCopy.filter((val) => val.activity !== "delete");
    dispatch(AddExperience(filteredData));
    const requestBody = {
      resume_id: Contactdata.resume_id ? Contactdata.resume_id : 0 ,
      methodId: 'experiences',
      exp_id: +id
    };
    makeApiRequest(requestBody)
  };
  useEffect(() => {
    
    if (count) {
      const addworkCopy = count.map((exp) => ({
        ...exp,
        startMonth:
          exp.startMonth,
           
        endYear:
          exp.endYear
      }));
      setWorkExpt(addworkCopy);
    }
  }, [count]);

  // useEffect(() => {
  //   
  //   const filteredData = copyworkExp.filter((val) => val.activity !== "delete");
  //   if(copyworkExp)
  //   {

  //     setWorkExpt(filteredData)
  //   }
  // }, [copyworkExp])

  const [addexpflag, setaddexpflag] = useState(true);

  const handlereditcase = (event) => {
    
    const getid = event;
    dispatch(EditExperience(getid));
    navigate("/AddNewExp");
  };

  const HandleNextStep = () => {
    
    // if (count.length) {
    if (!workpointer) {
      dispatch(Progress(24));
    }
    dispatch(workpointerFlagpointer(true));
    navigate("/Education");
    // } else {
    //   toast.error("Add Education");
    // }
  };
  return (
    <div className="col-lg-10 p-0 rightSideSection">
      <ToastContainer />
      <TopNav MainHeading={"Work Experience"} />
      {addexpflag ? (
        <>
          <div className="componentContainer paddingGen shadow">
          <div className="row mt-3 mb-3 ">
          <div className="col-lg-12">
            <h2 className="headingmain mt-4 mb-4">Add most relevant experience relating to your job role you are applying for
*Tip: if you lack experience you can ad internships or volunteer work.</h2>
          </div>
        </div>
            <div
              className="border border-dark mb-5"
              style={{
                borderRadius: "10px",
                borderColor: "rgb(126, 125, 130)",
              }}
            >
              {workExp.map((val) => (
                <div className="row " id={val.id} key={val.id}>
                  <div className="justify-content-center ">
                    <div className="p-2 pb-0">
                      <div className="card mb-0">
                        <div className="card-body">
                          <div className="row ">
                            <div className="col-md-6 text-right">
                              <h4 className="card-title text-sm-start">{`${
                                val.companyname
                              } - ${val.startMonth} to ${
                                val.currentlyWorking ? "Present" : val.endYear
                              }`}</h4>
                            </div>
                            <div className="col-md-6 text-lg-end">
                              <MdEdit
                                size={24}
                                className="mr-2 edit-icon"
                                id={val.id}
                                key={val.id}
                                onClick={() => handlereditcase(val.id)}
                              />
                              <MdDelete
                                size={24}
                                className="delete-icon"
                                id={val.id}
                                key={val.id}
                                onClick={() => DeleteRow(val.id)}
                              />
                            </div>
                          </div>
                          <div className="row mt-1">
                            <div className="col-md-12">
                              <p className="card-text text-sm-start">
                                {`${val.summary}`}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="row">
                <div className="justify-content-center ">
                  <div className="p-2">
                    <div className="card">
                      <div className="card-body">
                        <div className="row ">
                          <button
                            type="button"
                            class="btn btn-outline-ligh"
                            onClick={() => handleNavigate("/AddNewExp")}
                          >
                            <IoIosAddCircleOutline size={24} /> Add Experience
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end my-5">
              <button
                class="btn btn-secondary bg-white btn-back  w-25 me-3"
                onClick={() => handleNavigate("/Contact")}
              >
                Back
              </button>
              <button
                class="btn btn-delete w-25"
                onClick={HandleNextStep}
              >
                {`    `}Next
              </button>
            </div>
          </div>
        </>
      ) : (
        <AddNewExp />
      )}
    </div>
  );
};

export default WorkExperience;
