import axios from "axios";
const API_ENDPOINT = "https://cloudresumes.com/api/payment_form_api.php";
const BASE_URL = "https://cloudresumes.com/api/delete_resume_data.php"; 
export const stripePaymentMethodHandler = async (data, handleResponse) => {
  debugger
  const { amount, result, userdata } = data;
  // const payloadData ={
  //   token: result.paymentMethod.id,
  //   user_token : userdata.token,
  //   user_id:userdata.id,
  //   plan_id: amount
  // }
  if (result.error !== undefined) {
    // show error in payment form
    handleResponse(result);
  } else {
    const paymentResponse = await stripePayment({
      token: data.result.id,
      user_token: userdata.token,
      user_id: userdata.user_id,
      plan_id: amount,
    });
    console.log(paymentResponse);
    handleResponse(paymentResponse);
  }
};
// const axios = require('axios');
const stripePayment = async (payloadData) => {
  debugger;
  console.log(payloadData);
  try {
    const response = await axios.post(API_ENDPOINT, payloadData, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    // Handle the response data as needed
    return response.data;
  } catch (error) {
    // Handle errors, e.g., display error messages, etc.
    console.error("Error occurred during payment:", error);
    throw error; // Rethrow the error for higher-level error handling, if necessary
  }
};

export const makeApiRequest = async (body) => {
  debugger;
  try {
    const response = await axios({
      method: 'post', // HTTP method (GET, POST, PUT, DELETE, etc.)
      url: BASE_URL,
      data: body // Request body
    });
    if(response)
    {
      debugger
      

    }
     // Return the response data
  } catch (error) {
    throw error; // Throw an error if the request fails
  }
};