import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";

import { Modal, Button } from 'react-bootstrap';
import {
  useStripe, useElements,
  CardNumberElement, CardExpiryElement, CardCvcElement, Elements
} from '@stripe/react-stripe-js';
import { stripePaymentMethodHandler } from "./Services";
const stripePromise = loadStripe(
  "pk_test_51NgdivJrvqP6pDNWH7AeAMkhlLjuUgJipnLdxdMYJJrgUhDOq27iQkILBgDinbAZYFNBePtlbM8LPk5DJXDYSXdW008xqkgRPP"
);

function StripePaymentForm(props) {
  // const { show, onHide, heading, okButtonText } = props;
  const [isCardComplete, setCardComplete] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
 
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        lineHeight: "27px",
        color: "#212529",
        fontSize: "1.1rem",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };


  const handleSubmit = async (event) => {
    
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to load it before rendering this component.
      return;
    }

    setIsLoading(true);
    const paymentMethodObj = {
      type: 'card',
      card: elements.getElement(CardNumberElement),
      billing_details: {
        name,
        email
      },
    };
    const paymentMethodResult = await stripe.createPaymentMethod(paymentMethodObj);
 
    stripePaymentMethodHandler({
      result: paymentMethodResult,
      // amount: props.amount
    }, handleResponse);
    const handleResponse = response => {
      setLoading(false);
      if (response.error) {
        setErrorMsg(typeof response.error === 'string' ? response.error : response.error.message);
        return;
      }
      // props.setPaymentCompleted(response.success ? true : false);
    };

    // const card = elements.getElement(CardElement);
    // if (!card) {
    //   // Handle the case where the CardElement is not available
    //   setErrorMessage("Card input is not available.");
    //   setIsLoading(false);
    //   return;
    // }
    // const { token, error } = await stripe.createToken(card);

    // if (error) {
    //   setErrorMessage(error.message);
    //   setIsLoading(false);
    // } else {
    //   // Send the token and additional data to your backend
    //   const formData = new FormData();
    //   formData.append("token", token.id);
    //   formData.append(
    //     "user_token",
    //     document.getElementById("user_token").value
    //   );
    //   formData.append("user_id", document.getElementById("user_id").value);
    //   formData.append("plan_id", document.getElementById("plan_id").value);

    //   try {
    //     const response = await fetch(
    //       "https://cloudresumes.com/api/payment_form_api.php",
    //       {
    //         method: "POST",
    //         body: formData,
    //       }
    //     );

    //     if (response.ok) {
    //       // Payment succeeded, show a success message to the user
    //       // Hide the payment form
    //       document.getElementById("payment-form").style.display = "none";
    //       // Show the success message
    //       document.getElementById("success-container").style.display = "block";
    //       // Redirect after a delay (uncomment the following code if needed)
    //       /*
    //         setTimeout(() => {
    //           window.location.href = 'https://cloudresumes.com/api/thanks.php';
    //         }, 4000); // Redirect after 3 seconds (adjust as needed)
    //         */
    //     } else {
    //       // Payment failed, show an error message to the user
    //       setErrorMessage("Payment failed. Please try again.");
    //     }
    //   } catch (err) {
    //     console.error(err);
    //     setErrorMessage("An error occurred. Please try again later.");
    //   } finally {
    //     setIsLoading(false);
    //   }
    // }
  };

  

  return (
    <><div className="row p-5">
      <div className="col-md-12 mb-3">
        <label htmlFor="cc-number">Card Number</label>
        <CardNumberElement
          id="cc-number"
          className="form-control"
          options={CARD_ELEMENT_OPTIONS} />
      </div>
    </div><div className="row p-4">
        <div className="col-md-6 mb-3">
          <label htmlFor="expiry">Expiration Date</label>
          <CardExpiryElement
            id="expiry"
            className="form-control"
            options={CARD_ELEMENT_OPTIONS} />
        </div>
        <div className="col-md-6 mb-3">
          <label htmlFor="cvc">CVC</label>
          <CardCvcElement
            id="cvc"
            className="form-control"
            options={CARD_ELEMENT_OPTIONS} />
        </div>
      </div></>
)}

export default StripePaymentForm;
