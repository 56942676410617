import "./App.css";
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React, { useState, useEffect, useRef } from "react";
// import Login  from "../src/Components/Login.js";
import ForgetPassword from "../src/Components/ForgetPassword.js";
import WorkExperience from "../src/Components/WorkExperience";
import AddNewExp from "../src/Components/AddNewExp";
import SignUp from "../src/Components/SignUp.js";
import LoginUser from "../src/Components/LoginUser.js";
import Leftmanue from "../src/Components/Leftmenue.js";
import AdminLogin from "../src/Components/AdminLogin.js";
// import Login from "../src/Components/Login.js";
import Education from "../src/Components/Education.js";
import Contact from "../src/Components/Contact";
import AddEdu from "../src/Components/AddEdu";
import Certification from "../src/Components/Certification";
import AddCetification from "../src/Components/AddCetification";
import Skills from "../src/Components/Skills";
import CareerObjective from "../src/Components/CareerObjective";
import AddSkill from "../src/Components/AddSkill";
import References from "../src/Components/References";
import AddReferences from "../src/Components/AddReferences";
import Templates from "../src/Components/Templates";
import Profile from "../src/Components/Profile";
import Users from "../src/Components/Users";
import StripePaymentForm from "../src/Components/StripePaymentForm";
import SubscriptionComponent from "../src/Components/SubscriptionComponent";
import SubscriptionProfile from "../src/Components/SubscriptionProfile";
import Subscriptionadmin from "../src/Components/Subscriptionadmin";
import AddSubscriptionadmin from "../src/Components/AddSubscriptionadmin";
import Finalized from "../src/Components/Finalized";
import Blacktemp from "./CvTemplate/blacktemp";
import Tooltip from "./Components/Tooltip.js";
// import { useLocation } from 'react-router-dom';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  BrowserRouter,
} from "react-router-dom";
// import {BrowserRouter, Route, Routes,  HashRouter as Router,} from 'react-router-dom';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
// import { UserCredID } from "./Redux/counterSlice";
import { useDispatch } from "react-redux";
import Cv2 from "./CvTemplate/Cv2";
import {
  UserCredID,
  CerticateData,
  Educationstore,
  Referencesdatas,
  AddExperience,
  Skillsdata,
  GetContactDetails,
  CareerObjectivestore,
  imageupload,
  setWaterMark,
  ShowCV1,
  ShowCV2,
  ShowCV3,
  ShowCV4,
  ShowCV5,
  ShowCV6,
  ShowCV7,
  ShowCV8,
  Progress,
} from "./Redux/counterSlice";
import axios from "axios";
import MyAccount from "./Components/MyAccount.js";
const apiUrl = "https://cloudresumes.com/api/resume_get.php";
const apipaymentcheckUrl = "https://cloudresumes.com/api/fetch_user_data.php";

// import { loadStripe } from '@stripe/stripe-js';
let logindata = {};
function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const dispatch = useDispatch();

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  useEffect(() => {
    // debugger;
    logindata = JSON.parse(localStorage.getItem("UserCredID"));
    if (logindata) {
      const bodydata = {
        user_id: +logindata.user_id,
      };
      dispatch(UserCredID(logindata));
      
      checkpayment(apipaymentcheckUrl, bodydata);
      makePostRequest(apiUrl, bodydata);
    }
    return () => {
      // debugger;
    };
  }, []);

  const getLocalStorageValue = () => {
    let Contactdata = JSON.parse(localStorage.getItem("Contactdata"));
    let addexp = JSON.parse(localStorage.getItem("addexp"));
    let education = JSON.parse(localStorage.getItem("education"));
    let certifcates = JSON.parse(localStorage.getItem("certifcates"));
    let Skills_data = JSON.parse(localStorage.getItem("Skills_data"));
    let CareerObjective = JSON.parse(localStorage.getItem("CareerObjective"));
    let addReferences = JSON.parse(localStorage.getItem("addReferences"));
    let ContactImage = localStorage.getItem("ContactImage");
    dispatch(GetContactDetails(Contactdata || {}));
    dispatch(Skillsdata(Skills_data || []));
    dispatch(CerticateData(certifcates || []));
    dispatch(Educationstore(education || []));
    dispatch(AddExperience(addexp || []));
    dispatch(Referencesdatas(addReferences || []));
    dispatch(CareerObjectivestore(CareerObjective || []));
    dispatch(imageupload(ContactImage || ''));

    dispatch(Progress(100));
    // localStorage.removeItem("Contactdata");
    // localStorage.removeItem("addexp");
    // localStorage.removeItem("education");
    // localStorage.removeItem("certifcates");
    // localStorage.removeItem("Skills_data");
    // localStorage.removeItem("CareerObjective");
    // localStorage.removeItem("addReferences");
    // localStorage.removeItem("ContactImage");
    let ShowCV1s = JSON.parse(localStorage.getItem("ShowCV1"));
    let ShowCV2s = JSON.parse(localStorage.getItem("ShowCV2"));
    let ShowCV3s = JSON.parse(localStorage.getItem("ShowCV3"));
    let ShowCV4s = JSON.parse(localStorage.getItem("ShowCV4"));
    let ShowCV5s = JSON.parse(localStorage.getItem("ShowCV5"));
    let ShowCV6s = JSON.parse(localStorage.getItem("ShowCV6"));
    let ShowCV7s = JSON.parse(localStorage.getItem("ShowCV7"));
    let ShowCV8s = JSON.parse(localStorage.getItem("ShowCV8"));
    if(ShowCV1s)
    {
      dispatch(ShowCV1(true)); 
      localStorage.removeItem("ShowCV1");
    }
    if(ShowCV2s)
    {
      dispatch(ShowCV2(true)); 
      localStorage.removeItem("ShowCV2");
    }
    if(ShowCV3s)
    {
      dispatch(ShowCV3(true)); 
      localStorage.removeItem("ShowCV3");
    }
    if(ShowCV4s)
    {
      dispatch(ShowCV4(true)); 
      localStorage.removeItem("ShowCV4");
    }
    if(ShowCV5s)
    {
      dispatch(ShowCV5(true)); 
      localStorage.removeItem("ShowCV5");
    }
    if(ShowCV6s)
    {
      dispatch(ShowCV6(true)); 
      localStorage.removeItem("ShowCV6");
    }
    if(ShowCV7s)
    {
      dispatch(ShowCV7(true)); 
      localStorage.removeItem("ShowCV7");
    }
    if(ShowCV8s)
    {
      dispatch(ShowCV8(true)); 
      localStorage.removeItem("ShowCV8");
    }
  };

  // API call
  function checkpayment(apiUrl, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl, payload)
        .then((response) => {
          resolve(response.data);
          if (response.data) {
            if (response.data.data.subscription !== "none") {
              let checkresponepayment = JSON.parse(
                localStorage.getItem("UserCredID")
              );
              checkresponepayment.subscription =
                response.data.data.subscription;
                checkresponepayment ={...checkresponepayment , subscription_buydate : response.data.data.subscription_buydate, subscription_endDate: response.data.data.subscription_endDate}
              dispatch(UserCredID(checkresponepayment));
              localStorage.setItem(
                "UserCredID",
                JSON.stringify(checkresponepayment)
              );
              dispatch(setWaterMark(true));
            }
          }
          // setTimeout(() => {
          // }, 3000);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  function makePostRequest(apiUrl, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl, payload)
        .then((response) => {
          resolve(response.data);
          if (response.data.user_resumes !== undefined) {
            resumesdata(response.data.user_resumes[0]);
            dispatch(setWaterMark(true));
          }
          else
          {
            getLocalStorageValue()
          }
          setTimeout(() => {}, 3000);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  const resumesdata = (data) => {
    if (data.resume_info) {
      const newdatareferences = {
        firstName: data.resume_info.first_name,
        lastName: data.resume_info.last_name,
        jobTitle: data.resume_info.job_title,
        email: data.resume_info.email,
        phone: data.resume_info.phone,
        country: data.resume_info.country,
        city: data.resume_info.city,
        state: data.resume_info.state,
        zipCode: data.resume_info.zipcode,
        link1: data.resume_info.qrcode_url,
        linkLabel: data.resume_info.qrcode_label,
        resume_id: data.resume_id,
      };

      dispatch(GetContactDetails(newdatareferences));
      dispatch(
        CareerObjectivestore(data.resume_info.career_objective_paragraph)
      );
      dispatch(imageupload(data.resume_info.user_image_path));
    }
    if (data.skills) {
      const newDataSkill = data.skills.map(function (skills) {
        // Modify keys as needed
        return {
          id: skills.skill_id,
          SkillName: skills.skill_name,
        };
      });
      dispatch(Skillsdata(newDataSkill));
    }
    if (data.certifications) {
      const newData = data.certifications.map(function (certifications) {
        // Modify keys as needed
        return {
          id: certifications.certification_id,
          certTitle: certifications.title,
          selectedDate: certifications.date_issued,
        };
      });
      dispatch(CerticateData(newData));
    }
    if (data.education) {
      const newdataeducation = data.education.map(function (education) {
        // Modify keys as needed
        return {
          id: education.education_id,
          schoolName: education.school_name,
          schoollocation: education.school_location,
          fieldOfStudy: education.field_of_study,
          graduatemonth: education.start_graduation,
          graduateyear: education.end_graduation,
          summary: education.summary,
          degree: education.degree_program,
          studyContinue: education.currently_studying,
        };
      });
      dispatch(Educationstore(newdataeducation));
    }
    if (data.experiences) {
      const newdataexperiences = data.experiences.map(function (experiences) {
        // Modify keys as needed
        return {
          id: experiences.experience_id,
          companyname: experiences.company_name,
          startMonth: experiences.start_date,
          endYear: experiences.end_date,
          summary: experiences.summary,
          currentlyWorking: experiences.currently_working,
        };
      });
      dispatch(AddExperience(newdataexperiences));
    }
    if (data.references) {
      const newdatareferences = data.references.map(function (references) {
        // Modify keys as needed
        return {
          id: references.reference_id,
          FirstName: references.first_name,
          LastName: references.last_name,
          PositionTitle: references.position_title,
          Email: references.email,
          PhoneNumber: references.phone,
          OrganizationName: references.organization_name,
          PositionTitle: references.end_date,
          summary: references.summary,
          RelationShip: references.relationship,
        };
      });
      dispatch(Referencesdatas(newdatareferences));
    }

    // setLoading(false);
  };

  return (
    <BrowserRouter>
      <div className="container-fluid p-0">
        <div className="row">
          {}
          <Leftmanue />
          <Routes>
            {/* homepage url */}
            <Route path="/" element={<Contact />} />
            {/* public page */}
            <Route path="/Contact" element={<Contact />} />
            <Route path="/blacktempCv" element={<Blacktemp />} />
            <Route path="/WorkExperience" element={<WorkExperience />} />
            <Route path="/AddNewExp" element={<AddNewExp />} />
            <Route path="/Education" element={<Education />} />
            <Route path="/AddEdu" element={<AddEdu />} />
            <Route path="/certification" element={<Certification />} />
            <Route path="/AddCetification" element={<AddCetification />} />
            <Route path="/Skills" element={<Skills />} />
            <Route path="/AddSkill" element={<AddSkill />} />
            <Route path="/CareerObjective" element={<CareerObjective />} />
            <Route path="/References" element={<References />} />
            <Route path="/AddReferences" element={<AddReferences />} />
            <Route path="/Templates" element={<Templates />} />
            <Route path="/Finalized" element={<Finalized />} />
            {/* user pages */}
            <Route
              path="/SubscriptionProfile"
              element={<SubscriptionProfile />}
            />
             <Route path="/MyAccount" element={<MyAccount />} />
            <Route path="/LoginUser" element={<LoginUser />} />
            <Route path="/SignUp" element={<SignUp />} />
            <Route path="/ForgetPassword" element={<ForgetPassword />} />
            {/* admin pages */}
            <Route path="/Profile" element={<Profile />} />

            <Route path="/Subscriptionadmin" element={<Subscriptionadmin />} />
            <Route
              path="/AddSubscriptionadmin"
              element={<AddSubscriptionadmin />}
            />
            <Route path="/Users" element={<Users />} />
            <Route path="/AdminLogin" element={<AdminLogin />} />
            <Route path="/Subscription" element={<SubscriptionComponent />} />
            {/* 404 handle */}
            <Route path="*" element={<Contact />} />
            <Route path="/CV2" element={<Cv2 />} />
            <Route path="/Blacktemp" element={<Blacktemp />} />
            <Route path="/tooltips" element={<Tooltip />} />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
