// import React, { useEffect } from "react";
import React, { useState, useEffect, useRef } from "react";
// import './CustomComponent.css'; // Import the custom CSS file for the component
// import { IoMdCreate, IoMdTrash } from 'react-icons/io';
import { IoIosAddCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Blacktemp from "../CvTemplate/blacktemp";
import Cv2 from "../CvTemplate/Cv2";
import Cv3 from "../CvTemplate/CV3";
import Cv4 from "../CvTemplate/CV4";
import Cv5 from "../CvTemplate/CV5";
import { CareerObjectivestore, setWaterMark } from "../Redux/counterSlice";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import TopNav from "./TopNav";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Cv6 from "../CvTemplate/CV6";
import CV7 from "../CvTemplate/CV7";
import CV8 from "../CvTemplate/CV8"; 
const Finalized = () => {
  const [careerObjective, setCareerObjective] = useState("");
  const [disabled, setDisable] = useState(false);
  
  const Cv1TemplRef = useRef(null);
  const Cv1TemplRef1 = useRef(null);
  const Cv1TemplRef2 = useRef(null);
  const Cv1TemplRef3 = useRef(null);
  const Cv1TemplRef4 = useRef(null);
  const Cv1TemplRef6 = useRef(null);
  const Cv1TemplRef7 = useRef(null);
  const Cv1TemplRef8 = useRef(null);
  const Refdownload = useRef(null);
  const careerObjectiveRef = useRef();
  const dispatch = useDispatch();
  // show CV flag
  const TCv1 = useSelector((state) => state.counter.Cv1);
  const TCv2 = useSelector((state) => state.counter.Cv2);
  const TCv3 = useSelector((state) => state.counter.Cv3);
  const TCv4 = useSelector((state) => state.counter.Cv4);
  const TCv5 = useSelector((state) => state.counter.Cv5);
  const TCv6 = useSelector((state) => state.counter.Cv6);
  // const TCv6 = useSelector((state) => state.counter.Cv6);
  const TCv7 = useSelector((state) => state.counter.Cv7);
  const TCv8 = useSelector((state) => state.counter.Cv8);
  // Redux Data GEt
  const Contactdata = useSelector((state) => state.counter.initialState);
  const addexp = useSelector((state) => state.counter.addexp);
  const education = useSelector((state) => state.counter.addeducation);
  const certifcates = useSelector((state) => state.counter.certifcates);
  const Skills_data = useSelector((state) => state.counter.skillsdata);
  const CareerObjective = useSelector(
    (state) => state.counter.add_CareerObjective
  );

  const addReferences = useSelector((state) => state.counter.addReferences);
  const ContactImage = useSelector((state) => state.counter.image);
  const UserCreddata = useSelector((state) => state.counter.UserCred);

  const AddCareerObjectiv = () => {
    
    dispatch(CareerObjectivestore(careerObjective));
  };
  const handleObjectiveChange = () => {
    const newObjective = careerObjectiveRef.current.value;
    setCareerObjective(newObjective);
  };
  const navigate = useNavigate();
  const handleNavigate = (route) => {
    navigate(route);
  };
  useEffect(() => {
    // Perform navigation here
    navigate("/Finalized"); // Replace '/destination' with the actual path you want to navigate to
  }, []);
  const [childDivId, setChildDivId] = useState("");
  const pdf = new jsPDF();
  const generatePDF = () => {
    
    let input;
    if (TCv1) {
      input = Cv1TemplRef.current;
       html2canvas(input, {
        windowWidth: input.offsetWidth,
        windowHeight: input.offsetHeight,
        scrollX: window.scrollX,
        scrollY: window.scrollY,
        scale: 3
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
        const imgX = 0;
        const imgY = 0;
      
        pdf.addImage(
          imgData, 
          'PNG',
          imgX,
          imgY,
          imgWidth * ratio,
          imgHeight * ratio
        );
      
        pdf.save('cloud_resume.pdf');
      });
    }
    if (TCv2) {
      input = Cv1TemplRef1.current;
      html2canvas(input, {
        windowWidth: input.offsetWidth,
        windowHeight: input.offsetHeight,
        scrollX: window.scrollX,
        scrollY: window.scrollY,
        scale: 4
      }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4", true);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
        const imgX = 0;
        const imgY = 0;
  
        pdf.addImage(
          imgData,
          "PNG",
          imgX,
          imgY,
          imgWidth * ratio,
          imgHeight * ratio
        );
        pdf.save("cloud_resume.pdf");
      });
    }
    if (TCv3) {
      input = Cv1TemplRef2.current;
      html2canvas(input, {
        windowWidth: input.offsetWidth,
        windowHeight: input.offsetHeight,
        scrollX: window.scrollX,
        scrollY: window.scrollY,
        scale: 3      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
        const imgX = 0;
        const imgY = 0;
      
        pdf.addImage(
          imgData, 
          'PNG',
          imgX,
          imgY,
          imgWidth * ratio,
          imgHeight * ratio
        );
      
        pdf.save('Cloud_Resume.pdf');
      });
    }
    if (TCv4) {
      input = Cv1TemplRef3.current;
      html2canvas(input, {
        scale: 3 // You can adjust the scale factor if needed
    }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
        const imgX = 0;
        const imgY = 0;

        pdf.addImage(
            imgData,
            'PNG',
            imgX,
            imgY,
            imgWidth * ratio,
            imgHeight * ratio
        );

        pdf.save('Cloud_Resume.pdf');
    });
    }
    if (TCv5) {
      input = Cv1TemplRef4.current;
      html2canvas(input, {
        scale: 3 // You can adjust the scale factor if needed
    }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
        const imgX = 0;
        const imgY = 0;

        pdf.addImage(
            imgData,
            'PNG',
            imgX,
            imgY,
            imgWidth * ratio,
            imgHeight * ratio
        );

        pdf.save('cloud_resume.pdf');
    });
    }
    if (TCv6) {
      input = Cv1TemplRef6.current;
      html2canvas(input, {
        windowWidth: input.offsetWidth,
        windowHeight: input.offsetHeight,
        scrollX: window.scrollX,
        scrollY: window.scrollY,
        scale: 3
      }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4", true);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
        const imgX = 0;
        const imgY = 0;
  
        pdf.addImage(
          imgData,
          "PNG",
          imgX,
          imgY,
          imgWidth * ratio,
          imgHeight * ratio
        );
        pdf.save("cloud_resume.pdf");
      });
    }
    if (TCv7) {
      input = Cv1TemplRef7.current;
      html2canvas(input, {
        scale: 3 // You can adjust the scale factor if needed
    }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
        const imgX = 0;
        const imgY = 0;

        pdf.addImage(
            imgData,
            'PNG',
            imgX,
            imgY,
            imgWidth * ratio,
            imgHeight * ratio
        );

        pdf.save('cloud_resume.pdf');
    });
    }
    if (TCv8) {
      input = (Cv1TemplRef8.current);
      html2canvas(input, {
        scale: 3 // You can adjust the scale factor if needed
    }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
        const imgX = 0;
        const imgY = 0;

        pdf.addImage(
            imgData,
            'PNG',
            imgX,
            imgY,
            imgWidth * ratio,
            imgHeight * ratio
        );

        pdf.save('cloud_resume.pdf');
    });
    }
    // const input = Cv1TemplRef.current;
   
  };
  //DOwnloAd Requaist
  const ResumenHandleData = async (event) => {
    debugger;
    setDisable(true)
    Refdownload.current.style.backgroundColor = 'gray';
  Refdownload.current.style.Color = 'white';
  Refdownload.current.style.borderColor = 'white';
    let token = "";
    let Userid = "";
    let modifiedArray = [];
    let modifiedEducationArray = [];
    let certifcatesData = [];
    let modifiedaddReferences = [];
    let modifiedSkills_data = [];
    if (addexp.length > 0) {
      modifiedArray = addexp.map(function (item) {
        return {
          company_name: item.companyname,
          start_date: item.startMonth,
          end_date: item.endYear,
          summary: item.summary,
          currently_working: item.currentlyWorking,
        };
      });
      console.log(modifiedArray);
    }
    if (education.length > 0) {
      modifiedEducationArray = education.map(function (item) {
        return {
          school_name: item.schoolName,
          school_location: item.schoollocation,
          degree_program: item.degree,
          field_of_study: item.fieldOfStudy,
          start_graduation: item.graduatemonth,
          end_graduation: item.graduateyear,
          summary: item.summary,
          currently_studying: item.studyContinue,
        };
      });

      console.log(modifiedEducationArray);
    }
    if (certifcates.length > 0) {
      certifcatesData = certifcates.map(function (item) {
        return {
          title: item.certTitle,
          date_issued: item.selectedDate,
        };
      });

      console.log(modifiedEducationArray);
    }
    if (Skills_data.length > 0) {
      modifiedSkills_data = Skills_data.map(function (item) {
        return {
          skill_name: item.SkillName,
        };
      });

      console.log(modifiedEducationArray);
    }
    if (addReferences.length > 0) {
      debugger
      modifiedaddReferences = addReferences.map(function (item) {
        return {
          first_name: item.FirstName,
          last_name: item.LastName,
          position_title: item.PositionTitle,
          email: item.Email,
          phone: item.PhoneNumber,
          organization_name: item.OrganizationName,
          relationship: item.RelationShip,
          summary: item.summary,
        };
      });

      console.log(modifiedEducationArray);
    }
    if (Object.keys(UserCreddata).length !== 0) {
      if (UserCreddata.token && UserCreddata.subscription !== "none") {
        const DataForDownload = {
          user_id: UserCreddata.user_id,
          token: UserCreddata.token,
          first_name: Contactdata.firstName,
          last_name: Contactdata.lastName,
          phone: Contactdata.phone,
          job_title: Contactdata.jobTitle,
          country: Contactdata.country,
          city: Contactdata.city,
          state: Contactdata.state,
          zipcode: Contactdata.zipCode,
          email: Contactdata.email,
          qrcode_url: Contactdata.link1,
          qrcode_label: Contactdata.linkLabel,
          user_image_base64: ContactImage,
          career_objective_paragraph: CareerObjective,
          methodId: "EP02",
          experiences: modifiedArray,
          edMethodId: "EP03",
          educations: modifiedEducationArray,
          crMethodId: "EP04",
          certifications: certifcatesData,
          rfMethodId: "EP05",
          references: modifiedaddReferences,
          skMethodId: "EP06",
          skills: modifiedSkills_data,
        };

        try {
          const response = await axios.post(
            "https://cloudresumes.com/api/resume_api.php",
            DataForDownload,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          if (response.status === 200) {
            debugger;
            setDisable(false)
            Refdownload.current.style =Refdownload.current.style;
            toast.success("Your resume is ready, Downloading...");
            dispatch(setWaterMark(true))
            generatePDF();
          } else {
            toast.error(response.data.msg);
          }
        } catch (error) {
          console.error("An error occurred:", error);
        }
        // navigate("/Contact");
      }
      if (UserCreddata.token && UserCreddata.subscription === "none") {
        navigate("/SubscriptionProfile");
      }
    } else {
      navigate("/LoginUser");
    }
    if(UserCreddata.subscription === "none" || UserCreddata.subscription === undefined  )
    {
    localStorage.setItem('Contactdata', JSON.stringify(Contactdata));
    localStorage.setItem('addexp', JSON.stringify(addexp));
    localStorage.setItem('education', JSON.stringify(education));
    localStorage.setItem('certifcates', JSON.stringify(certifcates));
    localStorage.setItem('Skills_data', JSON.stringify(Skills_data));
    localStorage.setItem('CareerObjective', JSON.stringify(CareerObjective));
    localStorage.setItem('addReferences', JSON.stringify(addReferences));
    localStorage.setItem('ContactImage', (ContactImage));
    }
  };
  return (
    <div className="col-lg-10 p-0 rightSideSection">
      <ToastContainer />

      <TopNav MainHeading={"Templates"} />
      <div className="componentContainer paddingGen responsiveCV ">
        <div
          className="border border-dark shadow"
          style={{
            borderColor: "rgb(126, 125, 130)",
          }}
        >
          {TCv1 && (
            <div id="FirstCV">
              <Cv2 setChildDivId={setChildDivId} Cv1TemplRef={Cv1TemplRef} />
            </div>
          )}
          {TCv2 && (
            <div id="FirstCV1">
              <Blacktemp Cv1TemplRef1={Cv1TemplRef1} />
            </div>
          )}
          {TCv3 && (
            <div id="FirstCV2">
              <Cv3 Cv1TemplRef2={Cv1TemplRef2} />
            </div>
          )}
          {TCv4 && (
            <div id="FirstCV3">
              <Cv4 Cv1TemplRef3={Cv1TemplRef3} />
            </div>
          )}
          {TCv5 && (
            <div id="FirstCV4">
              <Cv5 Cv1TemplRef4={Cv1TemplRef4} />
            </div>
          )}
          {TCv6 && (
            <div id="FirstCV6">
              <Cv6 Cv1TemplRef6={Cv1TemplRef6} />
            </div>
          )}
          {TCv7 && (
            <div id="FirstCV7">
              <CV7 Cv1TemplRef7={Cv1TemplRef7} />
            </div>
          )}
          {TCv8 && (
            <div id="FirstCV8">
              <CV8 Cv1TemplRef8={Cv1TemplRef8} />
            </div>
          )}
          <div class="col-7 "></div>
          {/* <div class="col-2">
          <button class="btn btn-secondary bg-white border-primary text-primary w-25" onClick={() => handleNavigate('/Education')}>
            Back
          </button>
        </div>

        <div class="col-2">
          <button class="btn btn-primary w-25">{`    `}Next</button>
        </div> */}
        </div>
        <div className="d-flex justify-content-end my-5">
          <button
            className="btn btn-back bg-white  me-3 w-25"
            onClick={() => handleNavigate("/Templates")}
          >
            Back
          </button>
          <button
            className="btn btn-delete w-25 downloadButton"
            onClick={ResumenHandleData}
            disabled={disabled}
            ref={Refdownload }
          >
              { ` ${disabled ? 'Download....' :'Download'} `}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Finalized;
