// import React from "react";
// import './CustomComponent.css'; // Import the custom CSS file for the component
// import { IoMdCreate, IoMdTrash } from 'react-icons/io';
import { IoIosAddCircleOutline } from "react-icons/io";
import { MdEdit, MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Referencesdatas } from "../Redux/counterSlice";
import { EditReferences } from "../Redux/counterSlice";

import TopNav from "./TopNav";

const AddReferences = () => {
  const [formId, setFormId] = useState("");
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [PositionTitle, setPositionTitle] = useState("");
  const [Email, setEmail] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [OrganizationName, setOrganizationName] = useState("");
  const [RelationShip, setRelationShip] = useState("");
  const [summary, setSummary] = useState("");
  const addReferences = useSelector((state) => state.counter.addReferences);
  const editReferences = useSelector((state) => state.counter.edit_References);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const handleNavigate = (route) => {
    navigate(route);
  };
  useEffect(() => {
    
    if (editReferences) {
      const countcopy = [...addReferences];
      const findexpIndex = countcopy.findIndex((x) => x.id === editReferences);
      if(findexpIndex !== -1)
      {
      setFirstName(countcopy[findexpIndex].FirstName);
      setLastName(countcopy[findexpIndex].LastName);
      setPositionTitle(countcopy[findexpIndex].PositionTitle);
      setEmail(countcopy[findexpIndex].Email);
      setOrganizationName(countcopy[findexpIndex].OrganizationName);
      setRelationShip(countcopy[findexpIndex].RelationShip);
      setSummary(countcopy[findexpIndex].summary);
      setFormId(countcopy[findexpIndex].id);
      }
    }
    return () => {
      dispatch(EditReferences(false));
    };
  }, [editReferences]);

  function generateRandomId(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomId = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomId += characters.charAt(randomIndex);
    }

    return randomId;
  }
  const saveExpRecords = () => {
    let array = [];
    const randomId = generateRandomId(FirstName.length);
    if (addReferences) {
      array = [...addReferences];
    }
    const checkEditCase = array.findIndex((x) => x.id === formId);
    if (checkEditCase === -1) {
      const obj = {
        id: randomId,
        FirstName: FirstName,
        LastName: LastName,
        PositionTitle: PositionTitle,
        Email: Email,
        PhoneNumber: PhoneNumber,
        OrganizationName: OrganizationName,
        summary: summary,

        RelationShip: RelationShip,
      };
      array.push(obj);
      dispatch(Referencesdatas(array));
      handleNavigate("/References");
    } else {
      const obj = {
        id: randomId,
        FirstName: FirstName,
        LastName: LastName,
        PositionTitle: PositionTitle,
        Email: Email,
        PhoneNumber: PhoneNumber,
        OrganizationName: OrganizationName,
        RelationShip: RelationShip,
        summary: summary,
      };
      array[checkEditCase] = obj;
      dispatch(Referencesdatas(array));
      handleNavigate("/References");
    }
  };
  // else{
  //   handleNavigate('/References')
  // // }
  // };

  return (
    <div className="col-lg-10 p-0 rightSideSection">
      <TopNav MainHeading={"Add References"} />
      {/* First row */}
      <div className="componentContainer paddingGen shadow form-font">
        <div className="row text-start mb-3">
          <div className="col-6 col-lg-4">
            <label className="mb-1" htmlFor="FirstName">
              First Name (Optional)
            </label>
            <input
              type="text"
              className="form-control inputfield"
              id="FirstName"
              placeholder="First Name"
              value={FirstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className="col-6 col-lg-4">
            <label className="mb-1" htmlFor="LastName">
              Last Name (Optional)
            </label>
            <input
              type="text"
              className="form-control inputfield"
              id="LastName"
              placeholder="Last Name"
              value={LastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <div className="mt-3 mt-lg-0 col-lg-4">
            <label className="mb-1" htmlFor="PositionTitle">
              Position Title (Optional)
            </label>
            <input
              type="text"
              className="form-control inputfield"
              id="Position"
              placeholder="Position"
              value={PositionTitle}
              onChange={(e) => setPositionTitle(e.target.value)}
            />
          </div>
        </div>

        {/* Second row */}
        <div className="row text-start mb-3">
        <div className="mt-lg-0 col-lg-4">
            <label className="mb-1" htmlFor="Email">
              Email (Optional)
            </label>
            <input
              type="email"
              className="form-control inputfield"
              id="Email"
              placeholder="Email"
              value={Email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mt-3 mt-lg-0 col-lg-4">
            <label className="mb-1" htmlFor="OrganizationName">
              Organization Name (Optional)
            </label>
            <input
              type="text"
              className="form-control inputfield"
              id="Organization"
              placeholder="Organization Name"
              value={OrganizationName}
              onChange={(e) => setOrganizationName(e.target.value)}
            />
          </div>
          <div className="mt-3 mt-lg-0 col-lg-4">
            <label className="mb-1" htmlFor="RelationShip">
              Phone Number (Optional)
            </label>
            <input
              type="text"
              className="form-control inputfield"
              id="RelationShip"
              placeholder="Relation"
              value={RelationShip}
              onChange={(e) => setRelationShip(e.target.value)}
            />
          </div>
        </div>
        <div class="d-flex justify-content-end my-5">
        <button
              class="btn btn-secondary bg-white  me-3 w-25"
              onClick={() => handleNavigate("/References")}
            >
              Cancel
            </button>
            <button
              class="btn btn-primary w-25"
              onClick={saveExpRecords}
            >
              {`    `}Add
            </button>
        </div>
      </div>

      {/* Rest of your content here */}
    </div>
  );
};

export default AddReferences;
