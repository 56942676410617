// import React from "react";
// import './CustomComponent.css'; // Import the custom CSS file for the component
// import { IoMdCreate, IoMdTrash } from 'react-icons/io';
import { IoIosAddCircleOutline } from "react-icons/io";
import { MdEdit, MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Educationstore } from "../Redux/counterSlice";
import { EditEducation } from "../Redux/counterSlice";
import { ToastContainer, toast } from "react-toastify";
import DatePicker from "react-datepicker";

import TopNav from "./TopNav";
const AddEdu = () => {
  const [formId, setFormId] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [schoollocation, setSchoolLocation] = useState("");
  const [fieldOfStudy, setFieldOfStudy] = useState("");
  const [graduatemonth, setGraduateMonth] = useState("");
  const [graduateyear, setGraduateyear] = useState("");
  const [summary, setSummary] = useState("");
  const [degree, setDegree] = useState("");
  const [studyContinue, setcountinuestady] = useState(false);
  const education = useSelector((state) => state.counter.addeducation);
  const Getideditcase_edu = useSelector(
    (state) => state.counter.edit_education
  );
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const handleNavigate = (route) => {
    navigate(route);
    dispatch(EditEducation(false));
  };
  useEffect(() => {
    if (Getideditcase_edu) {
      const countcopy = [...education];
      const findexpIndex = education.findIndex(
        (x) => x.id === Getideditcase_edu
      );
      if(findexpIndex !== -1)
      {
      setSchoolName(countcopy[findexpIndex].schoolName);
      setSchoolLocation(countcopy[findexpIndex].schoollocation);
      setFieldOfStudy(countcopy[findexpIndex].fieldOfStudy);
      setGraduateMonth(countcopy[findexpIndex].graduatemonth);
      setDegree(countcopy[findexpIndex].degree);
      setSummary(countcopy[findexpIndex].summary);
      setcountinuestady(countcopy[findexpIndex].studyContinue);
      setFormId(countcopy[findexpIndex].id);
      }
    }
  }, [Getideditcase_edu]);
  function generateRandomId(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomId = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomId += characters.charAt(randomIndex);
    }

    return randomId;
  }

  const CheckValidField = () => {
    if (schoolName === undefined || schoolName.trim() === "") {
      toast.error("School Name Cannot Be Empty");
      return false;
    }

    if (schoollocation === undefined || schoollocation.trim() === "") {
      toast.error("School location Cannot Be Empty");
      return false;
    }

    if (degree === undefined || degree.trim() === "") {
      toast.error("Degree Cannot Be Empty");
      return false;
    }

    if (fieldOfStudy === undefined || fieldOfStudy.trim() === "") {
      toast.error("Field of Study Cannot Be Empty");
      return false;
    }
    if (!graduatemonth) {
      toast.error("Start Date Cannot Be Empty");
      return false;
    }

    if (!studyContinue && !graduateyear) {
      toast.error("End Date Cannot Be Empty");
      return false;
    }
    return true;
  };
  const saveExpRecords = () => {
    let array = [];
    const randomId = generateRandomId(schoolName.length);
    if (education) {
      array = [...education];
    }
    const checkEditCase = array.findIndex((x) => x.id === formId);
    if (checkEditCase === -1) {
      const obj = {
        id: randomId,
        schoolName: schoolName,
        schoollocation: schoollocation,
        fieldOfStudy: fieldOfStudy,
        graduatemonth: graduatemonth,
        graduateyear: graduateyear,
        summary: summary,
        degree: degree,
        studyContinue: studyContinue,
      };
      array.push(obj);
      dispatch(Educationstore(array));
      handleNavigate("/Education");
    } else {
      const obj = {
        id: randomId,
        schoolName: schoolName,
        schoollocation: schoollocation,
        fieldOfStudy: fieldOfStudy,
        graduatemonth: graduatemonth,
        graduateyear: graduateyear,
        summary: summary,
        degree: degree,
        studyContinue: studyContinue,
      };
      array[checkEditCase] = obj;
      dispatch(Educationstore(array));
      handleNavigate("/Education");
    }
    // }
  };
  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }
  const handleChange = (date) => {
    if (date) {
      const inputDates = new Date(date);
      const formattedDates = formatDate(inputDates);
      setGraduateMonth(formattedDates);
      console.log(date); // Output: 'dd-mm-yyyy'
    } else {
      setGraduateMonth(null);
    }
  };
  const handleChangeendYear = (date) => {
    if (date) {
      const inputDate = new Date(date);
      const formattedDate = formatDate(inputDate);
      setGraduateyear(formattedDate);
    } else {
      setGraduateyear(null);
    }
  };

  return (
    <div className="col-lg-10 p-0 rightSideSection ">
      <ToastContainer />

      <TopNav MainHeading={"Add Education"} />
      {/* First row */}
      <div className="componentContainer paddingGen shadow form-font">
        <div className="row  text-start mb-3  ">
          <div className="col-lg-4">
            <label className="mb-1" htmlFor="schoolName">
              School Name
            </label>
            <input
              type="text"
              className="form-control inputfield"
              id="SchoolName"
              placeholder="Enter The School Name"
              value={schoolName}
              onChange={(e) => setSchoolName(e.target.value)}
            />
          </div>
          <div className="mt-3 mt-lg-0 col-lg-4">
            <label className="mb-1" htmlFor="schoollocation">
              School Location
            </label>
            <input
              type="text"
              className="form-control inputfield"
              id="SchoolLocation"
              placeholder="Enter The School Location"
              value={schoollocation}
              onChange={(e) => setSchoolLocation(e.target.value)}
            />
          </div>
          <div className="mt-3 mt-lg-0 col-lg-4">
            <label className="mb-1" htmlFor="degree">
              Degree/Program
            </label>
            <input
              type="text"
              className="form-control inputfield"
              id="Degree"
              placeholder="Enter The Degree/Program"
              value={degree}
              onChange={(e) => setDegree(e.target.value)}
            />
          </div>
        </div>

        {/* Second row */}
        <div className="row text-start mb-3">
          <div className="col-lg-4">
            <label className="mb-1" htmlFor="fieldOfStudy">
              Field Of Study
            </label>
            <input
              type="text"
              className="form-control inputfield"
              id="FieldofStydy"
              placeholder=" Enter The Field Of Study"
              value={fieldOfStudy}
              onChange={(e) => setFieldOfStudy(e.target.value)}
            />
          </div>
          <div className="col-6 mt-3 mt-lg-0 col-lg-4">
            <label htmlFor="startdate" className="mb-1">
              Start Date
            </label>
            <br />
            <DatePicker
              value={graduatemonth}
              showYearDropdown
              minDate={new Date(1950, 0, 1)}
              maxDate={new Date()}
              yearDropdownItemNumber={100}
              scrollableYearDropdown
              onChange={handleChange}
              dateFormat="dd-MM-yyyy"
              showTimeSelect={false}
              className="form-control inputfield"
              id="GraduationMonth"
              placeholderText=" Enter The Start Date"
            />
          </div>
          {!studyContinue && (
            <div className="col-6 mt-3 mt-lg-0 col-lg-4 text-start ">
              <label htmlFor="endYear" className="mb-1">
                End Date
              </label>
              <br />
              <DatePicker
                showYearDropdown
                minDate={new Date(1950, 0, 1)}
                maxDate={new Date()}
                yearDropdownItemNumber={100}
                scrollableYearDropdown
                value={graduateyear}
                onChange={handleChangeendYear}
                dateFormat="dd-MM-yyyy"
                showTimeSelect={false}
                className="form-control inputfield" // Add custom-width class
                id="Graduationyear"
                placeholderText="Enter The End Date"
              />
            </div>
          )}
        </div>
        <div className="row mb-5">
          <div className="col">
            <div className="form-check text-start mt-0">
              <input
                type="checkbox"
                className="form-check-input"
                id="studyContinue"
                checked={studyContinue}
                onChange={(e) => setcountinuestady(e.target.checked)}
              />
              <label className="form-check-label" htmlFor="studyContinue">
                I Currently Study Here
              </label>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end my-5">
          <button
            class="btn btn-back bg-white w-25 me-3"
            onClick={() => handleNavigate("/Education")}
          >
            Cancel
          </button>
          <button class="btn btn-primary w-25" onClick={saveExpRecords}>
            {`    `}Add
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddEdu;
