import React, { useState, useEffect } from "react";
import axios from "axios";
import TopNav from "./TopNav";
import { FaCheck } from "react-icons/fa";
// import { loadStripe } from "@stripe/stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Planesubs, UserCredID } from "../Redux/counterSlice";
import { Elements } from "@stripe/react-stripe-js";
import CustomModal from "./CustomModal";
import { loadStripe } from "@stripe/stripe-js";
import { ToastContainer, toast } from "react-toastify";
import parse from "html-react-parser";

const MyAccount = () => {
  const userCred_id = useSelector((state) => state.counter.UserCred);
  function formatDateTimeToDateString(dateTimeString) {
    let originalDate = new Date(dateTimeString);
    let year = originalDate.getFullYear();
    let month = originalDate.getMonth() + 1;
    let day = originalDate.getDate();

    let formattedDateString = `${day}/${month}/${year}`;

    return formattedDateString;
  }
  const navigate = useNavigate();
  const handleNavigate = (route) => {
    navigate(route);
  };

  
  const dispatch = useDispatch();
  
  
const handleCancelSubscription = async (event) => {
  event.stopPropagation();
console.log(userCred_id.subscription);

  try {
    // Make a request to cancel_subscription.php
    const response = await axios.post("https://cloudresumes.com/api/cancel_subscription.php", {
      user_id: userCred_id.user_id, // Pass any necessary data
      user_token: userCred_id.token,
        });

    // Handle the response as needed
    console.log(response.data);
if(response.data.status=="success"){
  toast.success(response.data.msg);

    // Update Redux state if the cancellation is successful
    dispatch(UserCredID({ ...userCred_id, subscription: "none" }));
    // localStorage.setItem('subscription', 'none');

}else{
  toast.error(response.data.msg);
}
  } catch (error) {
    console.error("Error cancelling subscription:", error);
    // Handle errors if necessary
  }
};

  return (
    <div className="col-lg-10 p-0 rightSideSection">
            <ToastContainer />

      <TopNav MainHeading={"Account Information"} />
      <div className="componentContainer paddingGen shadow">
        <div className="row  mt-5">
          <div className="col-lg-6">
          <span className="d-flex">
          <h3>
            <b>Full Name:</b> {userCred_id.user_name}
          </h3>
        </span>
        <span className="d-flex pt-4">
          <h3>
            <b>Email:</b> {userCred_id.user_email}
          </h3>
        </span>
        <span className="d-flex pt-4">
          <h3>
            <b>Subscription Status:</b>{" "}
            {userCred_id.token && userCred_id.subscription === "none" ? (
              <span style={{ color: "red" }}>Inactive</span>
            ) : (
              <span style={{ color: "green" }}>Active</span>
            )}
          </h3>
        </span>
          </div>
          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-12"></div>
              <button
            class="btn btn-back w-50 backResumesBuilder"
            onClick={() => handleNavigate("/Contact")}
          >
            Back
          </button>
            </div>
            <div className="row mt-3">
              <div className="col-lg-12"></div>
              <button
            class="btn btn-back w-50 backResumesBuilder"
            onClick={() => handleNavigate("/SubscriptionProfile")}
          >
             Go to Subscription Page
          </button>
            </div>
          </div>
        </div>
       
     
        {userCred_id.subscription !== "none" && (
          <div>
            <span className="d-flex pt-4">
              <h3>
                <b>Subscription Name:</b> {userCred_id.subscription_name}
              </h3>
            </span>
            <span className="d-flex pt-4">
              <h3>
                <b>Subscription Date:</b>{" "}
                {formatDateTimeToDateString(userCred_id.subscription_buydate)}
              </h3>
            </span>
            <span className="d-flex pt-4">
              <h3>
                <b>Subscription Expiry Date:</b>{" "}
                {formatDateTimeToDateString(userCred_id.subscription_endDate)}
              </h3>
            </span>
            <button className="btn btn-back mt-5" onClick={handleCancelSubscription}>Cancel Subscription</button>
            <p className="mt-3" style={{fontSize:"16px", fontFamily:"sans-serif"}}>Once you cancel subscription your access to unlimited free downloads will cease 
immediately</p>
          </div>
        )} 
        <></>
      </div>
    </div>
  );
};

export default MyAccount;
