// import React from "react";
import React, { useState, useRef } from "react";
import axios from "axios";
// import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';

const ForgetPassword = () => {
  const [email, setEmail] = useState("");

  const emailRef = useRef(null);

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async () => {
    
    const data = {
      user_email: emailRef.current.value,
    };

    try {
      const response = await axios.post(
        "https://cloudresumes.com/api/forget_reset_password.php",
        data
      );

      if (response.status === 200) {
        // Handle successful login here
        alert(response.data.msg);
      } else {
        // Handle login error here
        alert(response.data.msg);
        console.error("Login failed");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <div className="container-fluid d-flex justify-content-center align-items-center vh-100">
      {/* Centered Logo */}
      <div className="text-center col-md-4">
        <div className="mx-3 side-menu-logo">
          <img
            src="./logo.png"
            alt="Logo"
            className="my-3"
            style={{ maxWidth: "45%" }}
          />
        </div>
        <div className="card mx-3 cards">
          <div className="card-body">
            <h3 className="card-title text-center mb-1"> Forgot Password</h3>
            <p className="col-8 card-title text-center mb-4 m-auto">
              {" "}{" "}{" "}
              Enter your email address to reset your password
            </p>
            <div className="form-group mb-4">
              <input
                type="text"
                className="form-control"
                id="username"
                placeholder="Email"
                ref={emailRef}
                value={email}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group mb-4">
              <button
                type="button"
                className="btn btn-primary btn-block w-100 mt-2"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
            <p>
              New User? <a href="/SignUp">Register</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
