import React, { useEffect, useState } from "react";
import WorkExperience from "../Components/WorkExperience";
import AddNewExp from "../Components/AddNewExp";
import Contact from "../Components/Contact";
import { Link } from "react-router-dom";
import { RiUserLine, RiLogoutBoxRLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import {
  RiContactsLine,
  RiBriefcaseLine,
  RiBook2Line,
  RiAwardLine,
  RiToolsLine,
  RiFileUserLine,
  RiUserReceivedLine,
  RiFileTextLine,
  RiCheckDoubleLine,
} from "react-icons/ri";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

function Leftmanue() {
  // code for handling height
  const [hasScrollbar, setHasScrollbar] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollbarWidth =
        window.innerWidth - document.documentElement.clientWidth;
      setHasScrollbar(scrollbarWidth > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const styles = {
    sidebar: {
      height: hasScrollbar ? "100%" : "100vh",
    },
  };
  // code for handling height ended

  const menuItems = [
    { text: "Contact", icon: <RiContactsLine />, selected: false },
    { text: "Experience", icon: <RiBriefcaseLine />, selected: false },
    { text: "Education", icon: <RiBook2Line />, selected: false },
    { text: "Certification", icon: <RiAwardLine />, selected: false },
    { text: "Skills", icon: <RiToolsLine />, selected: false },
    { text: "Career Objective", icon: <RiFileUserLine />, selected: false },
    { text: "References", icon: <RiUserReceivedLine />, selected: false },
    { text: "Templates", icon: <RiFileTextLine />, selected: false },
    { text: "Finalize", icon: <RiCheckDoubleLine />, selected: false },
  ];

  // const [activeItem, setActiveItem] = useState(null);
  const [adminScreen, setAdminScreen] = useState(false);
  const [adminFlag, SetAdminFlag] = useState(false);
  const [flag1, setFlag1] = useState(true);
  const [flag2, setFlag2] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const addexp = useSelector((state) => state.counter.addexp);
  const Contactdata = useSelector((state) => state.counter.initialState);
  const education = useSelector((state) => state.counter.addeducation);
  const ContactFlag = useSelector((state) => state.counter.ContactFlag);
  const workpointer = useSelector((state) => state.counter.workpointer);
  const navigate = useNavigate();
  const handleNavigate = (route) => {
    navigate(route);
  };
  const Educationpointer = useSelector(
    (state) => state.counter.Educationpointer
  );
  const Certificationpointer = useSelector(
    (state) => state.counter.Certificationpointer
  );
  const Skillspointer = useSelector((state) => state.counter.Skillspointer);
  const Referencespointer = useSelector(
    (state) => state.counter.Referencespointer
  );
  const CareerObjectivepointer = useSelector(
    (state) => state.counter.CareerObjectivepointer
  );
  const Templatespointer = useSelector(
    (state) => state.counter.Templatespointer
  );
  const Finalizepointer = useSelector(
    (state) => state.counter.FinalizePointerFlag
  );
  const Progress = useSelector((state) => state.counter.ProgressValue);
  const location = useLocation();

  const handleMenuItemClick = (text) => {
    if (
      (flag1 && text === "Certification") ||
      (flag2 && text === "Experience")
    ) {
      setSelectedItem(text);
    }
  }; 


  useEffect(() => {
    // debugger;
    const currentPath = window.location.pathname;
    if (
      currentPath === "/Profile" ||
      currentPath === "/Users" ||
      currentPath === "/Subscription" ||
      currentPath === "/Subscriptionadmin" ||
      currentPath === "/Logout"
    ) {
      setIsLoggedIn(true);
      SetAdminFlag(false);
    }
    if (
      currentPath === "/AdminLogin" ||
      currentPath === "/adminLogin" ||
      currentPath === "/LoginUser" ||
      currentPath === "/loginUser" ||
      currentPath === "/SignUp" ||
      currentPath === "/SubscriptionProfile" ||
      currentPath === "/ForgetPassword"
      // currentPath === "/MyAccount"
    ) {
      SetAdminFlag(true);
    }
    if (
      currentPath === "/Users" ||
      currentPath === "/Subscription" ||
      currentPath === "/Profile" ||
      currentPath === "/Subscriptionadmin" ||
      currentPath === "/Subscription" ||
      currentPath === "/AddSubscriptionadmin"
    ) {
      setAdminScreen(true);
    }
    if(currentPath === "/")
     {
      SetAdminFlag(false);
    }
    if(currentPath === "/Contact" || currentPath === "/Finalized")
     {
      SetAdminFlag(false);
    }
    if(currentPath === "/CV2" ||currentPath === "/Blacktemp") 
     {
      SetAdminFlag(true);
    }
  }, [location]);

  const HandLogOut =()=>{
    setTimeout(() => {
      window.location.reload()
    }, 100);
  }
  return (
    <>
      {!adminFlag && (
        <div className={`col-lg-2 p-0 leftSideSection ${adminScreen === true ? 'adminScreentrue' : ''}`}>
          <div className="sidebar sidenav px-3" style={styles.sidebar}>
            <div className="side-menu-logo text-center">
              <img
                src="./logo.png"
                alt="Logo"
                className="py-3"
                width="200px"
                onClick={() => handleNavigate(`${adminScreen === true ? '/Profile' : '/Contact'}`)}
                style={{ cursor: "pointer" }}
              />
            </div>
            <ul className="stepsMenu nav mt-3">
              {!isLoggedIn && (
                <>
                  <li className="nav-item ">
                    <Link to="/Contact" className="navedecorate">
                      <div className="nav-link d-flex align-items-center nasvitems">
                        <div style={{ marginRight: "5px" }}>
                          <RiContactsLine />
                        </div>
                        <div className="navedecorate">
                      
                          {"Contact"}</div>
                      </div>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/WorkExperience"
                      className="navedecorate"
                      style={{
                        cursor: ContactFlag ? "pointer" : "not-allowed",
                      }}
                      onClick={(e) => {
                        if (!ContactFlag) {
                          e.preventDefault();
                        }
                      }}
                    >
                      <div className="nav-link d-flex align-items-center nasvitems">
                        <div style={{ marginRight: "5px" }}>
                          <RiBriefcaseLine />
                        </div>
                        <div className="">{"Experience"}</div>
                      </div>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/Education"
                      className="navedecorate"
                      style={{
                        cursor: workpointer ? "pointer" : "not-allowed",
                      }}
                      onClick={(e) => {
                        if (!workpointer) {
                          e.preventDefault();
                        }
                      }}
                    >
                      <div className="nav-link d-flex align-items-center nasvitems">
                        <div style={{ marginRight: "5px" }}>
                          {" "}
                          <RiBook2Line />
                        </div>
                        <div className="">{"Education"}</div>
                      </div>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/certification"
                      className="navedecorate"
                      style={{
                        cursor: Educationpointer ? "pointer" : "not-allowed",
                      }}
                      onClick={(e) => {
                        if (!Educationpointer) {
                          e.preventDefault();
                        }
                      }}
                    >
                      <div className="nav-link d-flex align-items-center nasvitems">
                        <div style={{ marginRight: "5px" }}>
                          {" "}
                          <RiAwardLine />
                        </div>
                        <div className="">{"Certification"}</div>
                      </div>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/Skills"
                      className="navedecorate"
                      style={{
                        cursor: Certificationpointer
                          ? "pointer"
                          : "not-allowed",
                      }}
                      onClick={(e) => {
                        if (!Certificationpointer) {
                          e.preventDefault();
                        }
                      }}
                    >
                      <div className="nav-link d-flex align-items-center nasvitems">
                        <div style={{ marginRight: "5px" }}>
                          {" "}
                          <RiToolsLine />
                        </div>
                        <div className="">{"Skills"}</div>
                      </div>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/CareerObjective"
                      className="navedecorate"
                      style={{
                        cursor: Skillspointer ? "pointer" : "not-allowed",
                      }}
                      onClick={(e) => {
                        if (!Skillspointer) {
                          e.preventDefault();
                        }
                      }}
                    >
                      <div className="nav-link d-flex align-items-center nasvitems">
                        <div style={{ marginRight: "5px" }}>
                          {" "}
                          <RiFileUserLine />
                        </div>
                        <div className="">{"Career Objective"}</div>
                      </div>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/References"
                      className="navedecorate"
                      style={{
                        cursor: CareerObjectivepointer
                          ? "pointer"
                          : "not-allowed",
                      }}
                      onClick={(e) => {
                        if (!CareerObjectivepointer) {
                          e.preventDefault();
                        }
                      }}
                    >
                      <div className="nav-link d-flex align-items-center nasvitems">
                        <div style={{ marginRight: "5px" }}>
                          {" "}
                          <RiUserReceivedLine />
                        </div>
                        <div className="">{"References"}</div>
                      </div>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/Templates"
                      className="navedecorate"
                      style={{
                        cursor: Templatespointer ? "pointer" : "not-allowed",
                      }}
                      onClick={(e) => {
                        if (!Templatespointer) {
                          e.preventDefault();
                        }
                      }}
                    >
                      <div className="nav-link d-flex align-items-center nasvitems">
                        <div style={{ marginRight: "5px" }}>
                          {" "}
                          <RiFileTextLine />
                        </div>
                        <div className="">{"Templates"}</div>
                      </div>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/Finalized"
                      className="navedecorate"
                      style={{
                        cursor: Finalizepointer ? "pointer" : "not-allowed",
                      }}
                      onClick={(e) => {
                        if (!Finalizepointer) {
                          e.preventDefault();
                        }
                      }}
                    >
                      <div className="nav-link d-flex align-items-center nasvitems">
                        <div style={{ marginRight: "5px" }}>
                          {" "}
                          <RiCheckDoubleLine />
                        </div>
                        <div className="">{"Finalize"}</div>
                      </div>
                    </Link>
                  </li>
                </>
              )}
              {isLoggedIn && (
                <>
                  <li className="nav-item">
                    <Link to="/Profile" className="navedecorate">
                      <div className="nav-link d-flex align-items-center nasvitems">
                        <div>
                          <RiUserLine />
                        </div>
                        <div className="">{"Profile"}</div>
                      </div>
                    </Link>
                  </li>
                  <br />
                  <li className="nav-item">
                    <Link to="/Users" className="navedecorate">
                      <div className="nav-link d-flex align-items-center nasvitems">
                        <div style={{ marginRight: "5px" }}>
                          {" "}
                          <RiContactsLine />
                        </div>
                        <div className="">{"Users"}</div>
                      </div>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/Subscriptionadmin" className="navedecorate">
                      <div className="nav-link d-flex align-items-center nasvitems">
                        <div style={{ marginRight: "5px" }}>
                          {" "}
                          <RiFileTextLine />
                        </div>
                        <div className="">{"Subscription"}</div>
                      </div>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/AdminLogin" className="navedecorate" onClick={HandLogOut}>
                      <button className="nav-link d-flex align-items-center nasvitems">
                        <div style={{ marginRight: "5px" }}>
                          {" "}
                          <RiLogoutBoxRLine />
                        </div>
                        <div className="">{"Logout"}</div>
                      </button>
                    </Link>
                  </li>
                </>
              )}
            </ul>
            {!isLoggedIn && (
              <>
                <div className="px-3 py-3 progressBarsec">
                  <div className="progress border border-secondary">
                    <div
                      className="progress-bar process order-process"
                      role="progressbar"
                      style={{ width: `${Progress}%` }}
                      aria-valuenow={70}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {Progress}%
                    </div>
                  </div>
                  <h6 className="text-dark mt-2 " style={{ fontSize: "20px" }}>Progress</h6>
                </div>
              </>
            )}
          </div>
          {/* </div> */}
        </div>
      )}
    </>
  );
}
export default Leftmanue;
