import React, { useState, useEffect } from "react";
// import './CustomComponent.css'; // Import the custom CSS file for the component
// import { IoMdCreate, IoMdTrash } from 'react-icons/io';
import { IoIosAddCircleOutline } from "react-icons/io";
import { MdEdit, MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { CerticateData } from "../Redux/counterSlice";
import { useSelector, useDispatch } from "react-redux";
import { EditCertifications } from "../Redux/counterSlice";
import {
  CertificationpointerFlagpointer,
  Progress,
} from "../Redux/counterSlice";
import { ToastContainer, toast } from "react-toastify";

import TopNav from "./TopNav";
import { makeApiRequest } from "./Services";

const Certification = () => {
  const [certificationsdata, setCertificationsdata] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Certificationpointer = useSelector(
    (state) => state.counter.Certificationpointer
  );
  const education = useSelector((state) => state.counter.addeducation);
  const certifcates = useSelector((state) => state.counter.certifcates);
  const Contactdata = useSelector((state) => state.counter.initialState);

  const handleNavigate = (route) => {
    debugger;
    if (education.length === 0 && certifcates.length === 5) {
      return toast.error(`You can not add more than ${certifcates.length} cetificates`);
    }
    if (education.length === 1 && certifcates.length === 4) {
      return toast.error(`You can not add more than ${certifcates.length} cetificates`);
    }
    if (education.length === 2 && certifcates.length === 3) {
      return toast.error(`You can not add more than ${certifcates.length} cetificates`);
    }
    if (!(certifcates.length <= 4)) {
      return toast.error(`You can not add more than ${certifcates.length} cetificates`);
    }
    if (education.length === 3 && certifcates.length === 2) {
      return toast.error(`You can not add more than ${certifcates.length} cetificates`);
    } else {
      navigate(route);
    }
  };
  const setDate = (date) => {
    const formattedDate = `${date.getDate().toString().padStart(2, "0")}-${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${date.getFullYear()}`;
    return formattedDate;
  };
  useEffect(() => {
    if (certifcates) {
      const addcerticficaop = certifcates.map((changdate) => {
        if (changdate.selectedDate) {
          return {
            ...changdate,
            selectedDate: changdate.selectedDate,
          };
        } else {
          // Handle the case where selectedDate is null or undefined
          return changdate; // or return an appropriate default value
        }
      });
      setCertificationsdata(addcerticficaop);
    }
  }, [certifcates]);

  const handlereditcase = (event) => {
    const getid = event;
    dispatch(EditCertifications(getid));
    navigate("/AddCetification");
  };
  const DeleteRow = (id) => {
    const dataCopy = certifcates.map((value) => {
      if (value.id === id) {
        return { ...value, activity: "delete" };
      }
      return value;
    });
    const filteredData = dataCopy.filter((val) => val.activity !== "delete");
    dispatch(CerticateData(filteredData));
    const requestBody = {
      resume_id: Contactdata.resume_id ? Contactdata.resume_id : 0,
      methodId: "certifications",
      cert_id: +id,
    };
    makeApiRequest(requestBody);
  };
  const HandleNextStep = () => {
    // if(certifcates.length)
    // {
    if (!Certificationpointer) {
      dispatch(Progress(48));
    }
    // dispatch(Progress(36));
    dispatch(CertificationpointerFlagpointer(true));

    navigate("/Skills");
    // }
    // else{
    //   toast.error("Add Certification");
    //   dispatch(CertificationpointerFlagpointer(true));
    //   handleNavigate('/Skills');
    //   dispatch(Progress(48));
    // }
  };

  return (
    <div className="col-lg-10 p-0 rightSideSection">
      <ToastContainer />

      <TopNav MainHeading={"Add your relevant certificates *Tip: we recommend 2 or less"} />
      <div className="componentContainer paddingGen shadow">
      <div className="row mt-3 mb-3 ">
          <div className="col-lg-12">
            <h2 className="headingmain mt-4 mb-4">Add your relevant certificates *Tip: we recommend 2 or less</h2>
          </div>
        </div>
        <div
          className="border border-dark mb-5 pl-1 pr-1 "
          style={{
            borderRadius: "10px",
            borderColor: "rgb(126, 125, 130)",
          }}
        >
          {certificationsdata.map((val) => (
            <div className="row " id={val.id} key={val.id}>
              <div className="justify-content-center ">
                <div className="p-2 pb-0">
                  <div className="card mb-0">
                    <div className="card-body">
                      <div className="row ">
                        <div className="col-md-6 text-right">
                          <h4 className="card-title text-sm-start">{`${val.certTitle}`}</h4>
                        </div>
                        <div className="col-md-6 text-lg-end">
                          <MdEdit
                            className="mr-2 edit-icon"
                            size={24}
                            id={val.id}
                            key={val.id}
                            onClick={() => handlereditcase(val.id)}
                          />
                          <MdDelete
                            size={24}
                            onClick={() => DeleteRow(val.id)}
                            className="delete-icon"
                          />
                        </div>
                      </div>
                      <div className="row mt-1">
                        <div className="col-md-12">
                          <p className="card-text text-sm-start">
                            {val.selectedDate}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}

          <div className="row">
            <div className="justify-content-center ">
              <div className="p-2">
                <div className="card">
                  <div className="card-body">
                    <div className="row ">
                      <button
                        type="button"
                        class="btn btn-outline-ligh"
                        onClick={() => handleNavigate("/AddCetification")}
                      >
                        <IoIosAddCircleOutline size={24} /> Add Certification
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end my-5">
          <button
            class="btn btn-back bg-white w-25 me-3"
            onClick={() => handleNavigate("/Education")}
          >
            Back
          </button>
          <button class="btn btn-delete w-25" onClick={HandleNextStep}>
            {`    `}Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Certification;
