// import React from 'react';
// import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import React, { useState, useRef, useEffect, useHistory } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  UserCredID,
  CerticateData,
  Educationstore,
  Referencesdatas,
  AddExperience,
  Skillsdata,
  GetContactDetails,
  CareerObjectivestore,
  imageupload,
} from "../Redux/counterSlice";
import { useSelector, useDispatch } from "react-redux";
const apiUrl = "https://cloudresumes.com/api/resume_get.php";
let logindata ={}
const LoginUser = () => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const UserCreddata = useSelector((state) => state.counter.UserCred);

  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleNavigate = (route) => {
    navigate(route);
  };

  const dispatch = useDispatch();
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);
  useEffect(() => {
    logindata =  JSON.parse(localStorage.getItem('UserCredID'));
    if (logindata) {
      if (
        (window.location.pathname === "/" && logindata.token ) ||
        (window.location.pathname === "/LoginUser" && logindata.token)
      ) {
        navigate("/Contact");
      }
    }
  }, [window.location.pathname]);
  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };
  const resumesdata = (data) => {
    if (data.resume_info) {
      const newdatareferences = {
        firstName: data.resume_info.first_name,
        lastName: data.resume_info.last_name,
        jobTitle: data.resume_info.job_title,
        email: data.resume_info.email,
        phone: data.resume_info.phone,
        country: data.resume_info.country,
        city: data.resume_info.city,
        state: data.resume_info.state,
        zipCode: data.resume_info.zipcode,
        link1: data.resume_info.qrcode_url,
        linkLabel: data.resume_info.qrcode_label,
        resume_id:data.resume_id
      };

      dispatch(GetContactDetails(newdatareferences));
      dispatch(
        CareerObjectivestore(data.resume_info.career_objective_paragraph)
      );
      dispatch(imageupload(data.resume_info.user_image_path));
    }
    if (data.skills) {
      const newDataSkill = data.skills.map(function (skills) {
        // Modify keys as needed
        return {
          id: skills.skill_id,
          SkillName: skills.skill_name,
        };
      });
      dispatch(Skillsdata(newDataSkill));
    }
    if (data.certifications) {
      const newData = data.certifications.map(function (certifications) {
        // Modify keys as needed
        return {
          id: certifications.certification_id,
          certTitle: certifications.title,
          selectedDate: certifications.date_issued,
        };
      });
      dispatch(CerticateData(newData));
    }
    if (data.education) {
      const newdataeducation = data.education.map(function (education) {
        // Modify keys as needed
        return {
          id: education.education_id,
          schoolName: education.school_name,
          schoollocation: education.school_location,
          fieldOfStudy: education.field_of_study,
          graduatemonth: education.start_graduation,
          graduateyear: education.end_graduation,
          summary: education.summary,
          degree: education.degree_program,
          studyContinue: education.currently_studying,
        };
      });
      dispatch(Educationstore(newdataeducation));
    }
    if (data.experiences) {
      const newdataexperiences = data.experiences.map(function (experiences) {
        // Modify keys as needed
        return {
          id: experiences.experience_id,
          companyname: experiences.company_name,
          startMonth: experiences.start_date,
          endYear: experiences.end_date,
          summary: experiences.summary,
          currentlyWorking: experiences.currently_working,
        };
      });
      dispatch(AddExperience(newdataexperiences));
    }
    if (data.references) {
      const newdatareferences = data.references.map(function (references) {
        // Modify keys as needed
        return {
          id: references.reference_id,
          FirstName: references.first_name,
          LastName: references.last_name,
          PositionTitle: references.position_title,
          Email: references.email,
          PhoneNumber: references.phone,
          OrganizationName: references.organization_name,
          PositionTitle: references.end_date,
          summary: references.summary,
          RelationShip: references.relationship,
        };
      });
      dispatch(Referencesdatas(newdatareferences));
    }

    setLoading(false);
  };

  function makePostRequest(apiUrl, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl, payload)
        .then((response) => {
          resolve(response.data);
          if (response.data.user_resumes !== undefined) {
            resumesdata(response.data.user_resumes[0]);
          }
          setTimeout(() => {
            handleNavigate("/Contact");
            setLoading(false);
          }, 3000); // Resolve with response data
        })
        .catch((error) => {
          setLoading(false);
          reject(error); // Reject with error
        });
    });
  }
  const handleSubmit = async () => {
    if (passwordRef.current.value !== "" && usernameRef.current.value !== "") {
      const data = {
        user_email: usernameRef.current.value,
        user_password: passwordRef.current.value,
      };

      try {
        const response = await axios.post(
          "https://cloudresumes.com/api/user_login.php",
          data
        );

        if (response.status === 200) {

          if (response.data) {
            if(response.data.status === 'error')
            {
              toast.error('Invalid Email or Password')
            }
            if (
              response.data.subscription === "none" &&
              response.data.msg === "User authenticated"
            ) {
              toast.success('LogIn successfull, Loading...');
              dispatch(UserCredID(response.data));
              localStorage.setItem('UserCredID', JSON.stringify(response.data));
              setTimeout(() => {
                handleNavigate("/SubscriptionProfile");
                // setLoading(false)
              }, 1000);
            }
            if (
              response.data.subscription !== "none" &&
              response.data.msg === "User authenticated"
            ) {
              toast.success(response.data.msg);
              dispatch(UserCredID(response.data));
              localStorage.setItem('UserCredID', JSON.stringify(response.data));
              const bodydata = {
                user_id: +response.data.user_id,
              };
              makePostRequest(apiUrl, bodydata);
            }
          } else {
            toast.warning(response.error);
          }
        }
      } catch (error) {
        toast.error("Invalid Email or Password");
      }
    }
   
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      {/* <LoaderWithOverlay isLoading={isLoading} /> */}
      {/* {loading && (
      <div className="loader">Loading...</div>
    )} */}
      {/* Centered Logo */}
      <ToastContainer />

      <div className="text-center col-md-4 loginContainer">
      
        <div className="mx-3 side-menu-logo">
          <img
            src="./logo.png"
            alt="Logo"
            className="mb-3"
            style={{ maxWidth: "45%" }}
          />
        </div>
        <div className="card mx-3 cards">
          <div className="card-body">
          <h1 style={{fontSize:"20px",color:"#ff7900"}}>Enter Your Details</h1>
            <div className="form-group mb-4">
              <input
                type="text"
                className="form-control inputfield"
                id="username"
                placeholder="Enter your Email"
                ref={usernameRef}
                value={formData.username}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group mb-3">
              <input
                type="password"
                className="form-control inputfield"
                id="password"
                placeholder="Enter your Password"
                ref={passwordRef}
                value={formData.password}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group mb-4 text-start">
              <p>
                <a
                  style={{
                    textDecoration: "none",
                    color:"#ff7900"
                  }}
                  href="/ForgetPassword"
                >
                  Forgot Password?
                </a>
              </p>
            </div>
            <div className="form-group mb-4">
              <button
                type="button"
                className="btn btn-delete btn-block w-100 mt-3"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
            <p className="d-flex justify-content-center">
              New User?{" "}
              <p
                className="ms-2"
                style={{
                  color:"#ff7900",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleNavigate("/SignUp");
                }}
              >
                {" "}
                Register
              </p>
            </p>
              <p
                className="mt-2"
                style={{
                  color: "#ff7a00",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleNavigate("/");
                }}
              >
                {" "}
                - Back to Resume Builder -
              </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginUser;
