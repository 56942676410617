import React from "react";


const Tooltip = ({ content, children }) => {
  return (
    <div className="tooltip-container">
      <div className="tooltip-content">{content}</div>
      {children}
    </div>
  );
};

export default Tooltip;
