import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  FaPhone,
  FaEnvelope,
  FaMapMarkerAlt,
  FaLinkedin,
  FaMapMarker,
} from "react-icons/fa";
import { ShowCV2 } from "../Redux/counterSlice";
import QRCode from "qrcode.react";
let divStyle = {};
const Blacktemp = ({ Cv1TemplRef1 }) => {
  const Contactdata = useSelector((state) => state.counter.initialState);
  const Skills_data = useSelector((state) => state.counter.skillsdata);
  const education = useSelector((state) => state.counter.addeducation);
  const ReferencesData = useSelector((state) => state.counter.addReferences);
  const ContactImage = useSelector((state) => state.counter.image);
  const Experiencedata = useSelector((state) => state.counter.addexp);
  const removewaterMark = useSelector((state) => state.counter.waterMark);
  const CerticateData = useSelector((state) => state.counter.certifcates);
  const CareerObjective = useSelector(
    (state) => state.counter.add_CareerObjective
  );
  const dispatch = useDispatch();
  //   const divStyle = {
  //   color: '#000000',
  //   backgroundImage:removewaterMark?  " " :' url("watermark.png")', // Background image ka URL yahan par den
  //   backgroundSize: '60%, 20%', // Image ka size adjust karein
  //   backgroundRepeat: 'no-repeat',
  //   backgroundPosition: 'center',
  //   // backgroundColor: 'rgba(0, 0, 0, 0.5)', // 0.5 is the alpha (transparency) value
  //   // Image ko repeat nahi hone den
  // };
  const divStylew = {
    color: "#000000",
    backgroundColor: "#fff",
  };
  useEffect(() => {
    debugger;
    if (removewaterMark) {
      divStyle = {
        color: "#000000",
        backgroundImage: removewaterMark ? " " : ' url("watermark.png")', // Background image ka URL yahan par den
        backgroundSize: "65% , 20%", // Image ka size adjust karein
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        // backgroundColor: 'rgba(0, 0, 0, 0.5)', // 0.5 is the alpha (transparency) value
        // Image ko repeat nahi hone den
      };
    } else {
      divStyle = {
        color: "#000000",
        backgroundImage: removewaterMark ? " " : ' url("watermark.png")', // Background image ka URL yahan par den
        backgroundSize: "65% , 20%", // Image ka size adjust karein
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        // backgroundColor: 'rgba(0, 0, 0, 0.5)', // 0.5 is the alpha (transparency) value
        // Image ko repeat nahi hone den
      };
    }
  }, [removewaterMark]);
  return (
    <div
      class="containertemp text-light"
      ref={Cv1TemplRef1}
      id="secondcv"
      style={divStyle}
    >
      {/* <!-- HEADER SECTION --> */}
      <div class="headercv" style={{ alignItems: "center" }}>
        <div class="left" style={{ width: "50%" }}>
          <div
            class="full-name garamondcv nunito"
            style={{ color: "#595959", display: "flex", flexWrap: "wrap" }}
          >
            <span class="first-name">{Contactdata.firstName}</span>
            &nbsp;
            <span class="last-name">{Contactdata.lastName}</span>
          </div>
          <div
            class="about nunito bordercv"
            style={{ color: "#000000", padding: "0.4rem 0", width: "98%" }}
          >
            <span class="position">{Contactdata.jobTitle}</span>
          </div>
        </div>
        {/* <div class="right"> */}
        {Contactdata.link1 && (
          <div
            className="d-flex justify-content-center flex-column align-items-center mt-2"
            style={{ width: "20%" }}
          >
            <QRCode
              value={Contactdata.link1}
              size={75}
              fgColor="#000"
              bgColor="#fff"
              level="H"
            />
            <label className="qrTitle text-center">
              {Contactdata.linkLabel}
            </label>
          </div>
        )}

        {/* </div> */}
        {ContactImage && (
          <div
            class="right"
            style={{
              width: "18.6%",
              borderRadius: "50%",
            }}
          >
            <div
              style={{
                backgroundImage: `url(${ContactImage})`,
                backgroundSize: "cover",
                width: 130,
                height: 130,
                borderRadius: "50%",
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
              }}
            />
            {/* <img
              src={ContactImage}
              // alt="Uploaded"
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "cover",
              }}
            /> */}
          </div>
        )}
      </div>
      {/* <!-- END HEADER SECTION --> */}
      <div class="detailscv">
        <div
          class="section main-layoutcv"
          style={{ borderTop: "none", color: "#000000" }}
        >
          {/* <!-- BEGIN SIDEBAR SECTION --> */}
          <div class="sidebarcv">
            {/* <!-- BEGIN CONTACT SECTION --> */}
            <div class="section">
              <div class="section__title headingcv">CONTACT </div>
              <div class="section__list">
                <div class="section__list-item">
                  <ul className="p-0 contactItems">
                    <li>
                      <FaPhone />
                      <a href={`href:${Contactdata.phone}`}>
                        {Contactdata.phone}
                      </a>
                    </li>
                    <li>
                      <FaEnvelope />
                      <a href={`mailto:${Contactdata.email}`}>
                        {Contactdata.email}
                      </a>
                    </li>
                    <li style={{ textTransform: "uppercase" }}>
                      <span>
                        <FaMapMarkerAlt />
                      </span>
                      {`${
                        Contactdata.zipCode
                          ? `${Contactdata.zipCode}${","} `
                          : ""
                      }
                      ${
                        Contactdata.city ? `${Contactdata.city}${","} ` : ""
                      }
                      ${
                        Contactdata.state ? `${Contactdata.state}${","} ` : ""
                      } ${Contactdata.country ? `${Contactdata.country}` : ""}`}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <!-- END SIDEBAR SECTION --> */}

            {/* <!-- BEGIN SKILLS  SECTION --> */}
            {Skills_data.length > 0 &&  <div class="section">
              <div class="section__title headingcv">SKILLS </div>
              <div class="section__list">
                <div class="section__list-item">
                  <ul
                    class="sidebar-dotscv p-0 "
                    style={{ listStyleType: "disc" }}
                  >
                    <li style={{ listStyle: "none" }}>
                      <div
                        class="sub-heading nunito"
                        style={{
                          color: "#000000",
                          textTransform: "uppercase",
                          letterSpacing: "0px",
                        }}
                      ></div>
                    </li>
                    {Skills_data.map((Skill) => (
                      <li key={Skill.id}>{Skill.SkillName}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>}
            {/* <!-- END SKILLS  SECTION --> */}
            {/* START EDUCATION */}
            {education.length > 0 &&  <div class="section">
              <div class="section__title headingcv">{CerticateData.length > 0 ? 'EDUCATION & CERTIFICATION' : 'EDUCATION'}</div>
              {education.map((value) => (
                <div key={value.id}>
                  {" "}
                  {/* Make sure to provide a unique key */}
                  <div className="sub-heading">
                    {value.degree} | {value.fieldOfStudy}
                  </div>
                  <div className="organization">
                    {value.schoolName} | {value.schoollocation}
                  </div>
                  <div
                    className="year eduYear"
                    style={{ marginBottom: "20px" }}
                  >
                    {value.graduatemonth ? value.graduatemonth : ""}{" "}
                    {value.studyContinue ? "In Progress" : value.graduateyear}
                  </div>
                </div>
              ))}
            </div>}
            {/* EDUCATION END  */}
            {/* <!-- BEGIN CERTIFICATION SECTION --> */}
            { CerticateData.length > 0 &&    <div class="section">
              {/* <div class="section__title headingcv">CERTIFICATIONS</div> */}
              {CerticateData.map((value) => (
                <div className="mb-4" key={value.id}>
                  <div>
                    <b>{value.certTitle}</b>
                  </div>
                  <div>{value.selectedDate}</div>
                </div>
              ))}
            </div>}
            {/* <!-- END CERTIFICATION  SECTION --> */}
          </div>
          {/* <!-- END SIDEBAR  SECTION --> */}

          {/* <!-- BEGIN RIGHTBAR  SECTION --> */}
          <div class="rightbarcv">
            {/* <!-- BEGIN SUMMARY  SECTION --> */}
            { CareerObjective &&  <div class="section">
              <div class="section__title headingcv">SUMMARY</div>
              <div class="text careerObj">{CareerObjective}</div>
            </div>}
            {/* <!-- END SUMMARY  SECTION --> */}

            {/* <!-- BEGIN EXPERIENCE  SECTION --> */}
            {Experiencedata.length > 0 && <div class="section">
              <div class="section__title headingcv">WORK EXPERIENCE</div>

              {Experiencedata.map((exp) => (
                <div style={{ marginBottom: "20px" }} key={exp.id}>
                  {" "}
                  {/* Make sure to provide a unique key */}
                  <div class="position-yearcv">
                    <div class="sub-heading">{Contactdata.jobTitle}</div>
                    <div class="year">{`${
                      exp.startMonth ? exp.startMonth : ""
                    } - ${
                      exp.currentlyWorking ? "Present" : exp.endYear
                    }`}</div>
                  </div>
                  <div>{exp.companyname}</div>
                  <div class="text expSummary">{exp.summary}</div>
                </div>
              ))}
            </div>}
            {/* <!-- END EXPERIENCE  SECTION --> */}


          </div>
          {/* <!-- END RIGHTBAR  --> */}
        </div>
        {ReferencesData.length > 0 &&  <div
          className="references"
          style={{ width: "790px", position: "absolute", bottom: "30px" }}
        >
          <div className="section__title headingcv">References</div>
          <div className="references__layout2">
            {ReferencesData.map((value, index) => (
              <div className="col-4" key={index}>
                <div className="text">
                  {value.FirstName}&nbsp;{value.LastName}&nbsp;
                  {value.PositionTitle}&nbsp;{value.OrganizationName}
                  <br />
                  <a href={`mailto:${value.Email}`}>{value.Email}</a>
                  <br />
                  <a href={`tel:${value.RelationShip}`}>{value.RelationShip}</a>
                </div>
              </div>
            ))}
          </div>
        </div>}
      </div>
    </div>
  );
};

export default Blacktemp;
