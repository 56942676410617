// import React, { useEffect } from "react";
import React, { useState, useEffect, useRef } from "react";
// import './CustomComponent.css'; // Import the custom CSS file for the component
// import { IoMdCreate, IoMdTrash } from 'react-icons/io';
import { IoIosAddCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { CareerObjectivestore } from "../Redux/counterSlice";
import {
  CareerObjectivepointerFlagpointer,
  Progress,
} from "../Redux/counterSlice";

import TopNav from "./TopNav";

const CareerObjective = () => {
  const [careerObjective, setCareerObjective] = useState("");
  const careerObjectiveRef = useRef();
  const dispatch = useDispatch();
  const CareerObjectivepointer = useSelector(
    (state) => state.counter.CareerObjectivepointer
  );
  const CareerObjective = useSelector(
    (state) => state.counter.add_CareerObjective
  );
  const Contactdata = useSelector((state) => state.counter.initialState);
  const education = useSelector((state) => state.counter.addeducation);
  const certifcates = useSelector((state) => state.counter.certifcates);

  const AddCareerObjectiv = () => {
    if (!CareerObjectivepointer) {
      dispatch(Progress(72));
    }
    dispatch(CareerObjectivestore(careerObjective));
    dispatch(CareerObjectivepointerFlagpointer(true));
    handleNavigate("/References");
  };
  const handleObjectiveChange = () => {
    const newObjective = careerObjectiveRef.current.value;
    setCareerObjective(newObjective);
  };
  const navigate = useNavigate();
  const handleNavigate = (route) => {
    navigate(route);
  };
  useEffect(() => {
    // Perform navigation here
    navigate("/CareerObjective"); // Replace '/destination' with the actual path you want to navigate to
  }, []);
  useEffect(() => {
    if (CareerObjective) {
      setCareerObjective(CareerObjective);
    }
  }, [CareerObjective]);
  const SuggestionsFirst = `Energetic and enthusiastic recent graduated [Education] seeking an entry-level opportunity to launch a successful career. Possesses a solid foundation in [Certification/Skills]. Demonstrated ability to learn quickly and work collaboratively within a team. Strong communication and problem-solving skills. Excited to bring my fresh perspective and dedication to [target industry/company] to make a meaningful impact and grow professionally.`;
  const SuggestionsSecond = `Versatile and dynamic professional with a strong background in [Skills/industry]. Experienced in [specific areas of expertise, e.g., marketing, finance, operations]. Demonstrated ability to [mention key accomplishments or skills]. Committed to optimizing efficiency and profitability while maintaining a keen eye for detail and accuracy. Seeking an opportunity [*Optional*with target company/organization] to contribute my skills and insights to drive growth and achieve mutual success.`;
  const SuggestionsThird = `Accomplished and results-driven professional with [X years] of experience in [industry/field], excelling in leadership roles. Proven track record of driving [specific achievements or outcomes] through effective team management and strategic decision-making. Adept at fostering a collaborative and inclusive work environment to optimize productivity and achieve corporate objectives. Known for [key leadership quality, e.g., inspiring teams, problem-solving, or innovation]. Seeking a leadership position [ *Optional* with target company/organization] to leverage my expertise and drive continued success.`;
  const SuggestionsFourth = `Highly qualified and accomplished [your profession/field] expert with a combined [X years] of experience. Proven track record of delivering exceptional results through [specific skills or achievements]. Possess in-depth knowledge of [industry-specific knowledge or tools]. Recognized for [notable recognition or accolades]. Committed to delivering excellence and exceeding expectations. Seeking a challenging role [*Optional* with target company/organization] where I can continue to apply my expertise and make a significant impact.`;
  const SuggestionsFifth = `Seasoned and results-oriented professional with an extensive [X years] of experience in [industry/field]. Demonstrated expertise in [specific areas or skills, e.g., project management, sales, research]. Consistently achieved [mention significant achievements or milestones]. Proven ability to adapt to evolving challenges and lead teams to success. Seeking a senior-level position [*Optional* with target company/organization] to leverage my wealth of experience and contribute to strategic growth.`;

  const AddCetificateSuggert = (addCert) => {
    if (addCert === "First") {
      dispatch(CareerObjectivestore(SuggestionsFirst));
    }
    if (addCert === "Second") {
      dispatch(CareerObjectivestore(SuggestionsSecond));
    }
    if (addCert === "Third") {
      dispatch(CareerObjectivestore(SuggestionsThird));
    }
    if (addCert === "Fourth") {
      dispatch(CareerObjectivestore(SuggestionsFourth));
    }
    if (addCert === "Fifth") {
      dispatch(CareerObjectivestore(SuggestionsFifth));
    }
  };

  return (
    <div className="col-lg-10 p-0 rightSideSection">
      <TopNav MainHeading={"Write or use our sample career objectives *Tip: add any relevant information you wish "} />
      <div className="componentContainer paddingGen shadow">
      <div className="row mt-3 mb-3 ">
          <div className="col-lg-12">
            <h2 className="headingmain mt-4 mb-4">Choose from our sample objectives or create your own,
*Tip: If you have any other relevant information you wish i.e hobbies interest ect ad them here</h2>
          </div>
        </div>
        <div
          className="border border-dark"
          style={{
            borderRadius: "10px",
            borderColor: "rgb(126, 125, 130)",
          }}
        >
          <div className="row">
            <div className="justify-content-center d-lg-flex col">
              <div className="p-2 mt-3 mt-lg-0 col-lg-12">
                <h4>Your Career Objective</h4>
                <textarea
                  className="custom-textarea w-100"
                  placeholder="Write Your Career Objective Here"
                  ref={careerObjectiveRef}
                  onChange={handleObjectiveChange}
                  value={careerObjective}
                />
                <p className="p-0 m-0">
                  Character count: {careerObjective.length}
                </p>
              </div>
             
            </div>
          </div>
          <div className="row">
            <div className="justify-content-center d-lg-flex col">
              
              <div className="p-2 col-md-12">
                <h5>Suggestions</h5>
                {/* first suggestion  */}
                <div
                  className="custom-textarea-small mt-3 mb-2"
                  style={{ width: "100%", height: "9em", background: "white" }}
                >
                  <div style={{ height: "100%", overflow: "auto" }}>
                    <IoIosAddCircleOutline
                      size={24}
                      onClick={() => AddCetificateSuggert("First")}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />{" "}
                    <b>New to Workforce : </b>
                    <br />
                    <p>{SuggestionsFirst}</p>
                  </div>
                </div>

                {/* second suggestion */}
                <div
                  className="custom-textarea-small my-2"
                  style={{
                    width: "100%",
                    height: "9em",
                    background: "white",
                    overflow: "hidden", // Hide overflow from the parent div
                  }}
                >
                  <div style={{ height: "100%", overflow: "auto" }}>
                    {" "}
                    {/* Scrollable div */}
                    <IoIosAddCircleOutline
                      size={24}
                      onClick={() => AddCetificateSuggert("Second")}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />{" "}
                    <b>General Business : </b>
                    <br />
                    <p>{SuggestionsSecond}</p>
                  </div>
                </div>

                {/* third suggestion */}
                <div
                  className="custom-textarea-small my-2"
                  style={{
                    width: "100%",
                    height: "9em",
                    background: "white",
                    overflow: "hidden", // Hide overflow from the parent div
                  }}
                >
                  <div style={{ height: "100%", overflow: "auto" }}>
                    {" "}
                    {/* Scrollable div */}
                    <IoIosAddCircleOutline
                      size={24}
                      onClick={() => AddCetificateSuggert("Second")}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />{" "}
                    <b>Leadership Role : </b>
                    <br />
                    <p>{SuggestionsThird}</p>
                  </div>
                </div>

                {/* fourth suggestion */}
                <div
                  className="custom-textarea-small my-2"
                  style={{
                    width: "100%",
                    height: "9em",
                    background: "white",
                    overflow: "hidden", // Hide overflow from the parent div
                  }}
                >
                  <div style={{ height: "100%", overflow: "auto" }}>
                    {" "}
                    {/* Scrollable div */}
                    <IoIosAddCircleOutline
                      size={24}
                      onClick={() => AddCetificateSuggert("Second")}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />{" "}
                    <b>Highly Qualified : </b>
                    <br />
                    <p>{SuggestionsFourth}</p>
                  </div>
                </div>

                {/* fifth suggestion */}
                <div
                  className="custom-textarea-small my-2"
                  style={{
                    width: "100%",
                    height: "9em",
                    background: "white",
                    overflow: "hidden", // Hide overflow from the parent div
                  }}
                >
                  <div style={{ height: "100%", overflow: "auto" }}>
                    {" "}
                    {/* Scrollable div */}
                    <IoIosAddCircleOutline
                      size={24}
                      onClick={() => AddCetificateSuggert("Second")}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />{" "}
                    <b>Highly Experienced: </b>
                    <br />
                    <p>{SuggestionsFifth}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end my-5">
          <button
            class="btn btn-back bg-white  me-3 w-25"
            onClick={() => handleNavigate("/Skills")}
          >
            Back
          </button>
          <button class="btn btn-delete w-25" onClick={AddCareerObjectiv}>
            {`    `}Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default CareerObjective;
