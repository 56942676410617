// import React from 'react';
// import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import React, { useState, useRef } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { UserCredID } from "../Redux/counterSlice";

const TopNav = ({ MainHeading }) => {
  const AdminCred_id = useSelector((state) => state.counter.AdminCred);
  const userCred_id = useSelector((state) => state.counter.UserCred);
  const Contactdata = useSelector((state) => state.counter.initialState);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleNavigate = (route) => {
    navigate(route);
  };
  const handlechangeURL = () => {
    debugger;
    dispatch(UserCredID({}));
    localStorage.setItem("UserCredID", JSON.stringify({}));
    setTimeout(() => {
      navigate("/LoginUser");
      localStorage.removeItem("Contactdata");
    localStorage.removeItem("addexp");
    localStorage.removeItem("education");
    localStorage.removeItem("certifcates");
    localStorage.removeItem("Skills_data");
    localStorage.removeItem("CareerObjective");
    localStorage.removeItem("addReferences");
    localStorage.removeItem("ContactImage");
      window.location.reload()
    }, 1000);
  };

  const handlechangeGuest = () => {
    debugger;
    if (
      Object.keys(userCred_id).length === 0 &&
      userCred_id.status === undefined
    ) {
      navigate("/LoginUser");
    } else {
      navigate("/SubscriptionProfile");
    }
  };


  const OnNextchange = () => {
    
    // const validation = checkvalidation();
    // if (validation) {

    //   dispatch(Progress(12));
    // let newobj = { ...formData };
    // dispatch(GetContactDetails(newobj));
    // dispatch(ContactFlagpointer(true));
    // handleNavigate("/WorkExperience");
    // }
  };
  

  return (
    <div className="topNavHeader w-100">
      <nav
        className="mainNavbar paddingGen navbar navbar-expand-md navbar-dark bg-body-tertiary  d-flex justify-content-center justify-content-lg-between"
        style={{
          height: "70px",
        
    // box-shadow: ;
    boxShadow: "1px solid black",
          top: "0",
          right: "0",
        }}
      >
        {/* <div className="container-fluid justify-content-lg-start"> */}
        {MainHeading === "Profile" && (
          <div className="side-menu-logo">
            <img
              src="./logo.png"
              alt="Logo"
              className="my-3"
              style={{ maxWidth: "150px", cursor: 'pointer' }}
              onClick={() => handleNavigate("/Contact")}
            />
          </div>
        )}
        <span
          className="text-dark "
          onClick={handlechangeGuest}
          style={{
            cursor: "pointer",
            textDecoration: "none",
            fontSize: "20px",
            fontWeight:"500",
            
          }}
        >
          {Object.keys(userCred_id).length !== 0 ||
          userCred_id.status !== undefined
            ? `Hello, ${
                userCred_id.user_name ? userCred_id.user_name : "Subscriber"
              }`
            : Object.keys(AdminCred_id).length !== 0 ? "Hello, Admin" : "Hello, Guest!"}
        </span>
        <span className="m-0 navbar-brand text-black navBarItemsMob">
          <a
            href="https://cloudresumes.com/#bottom_footer"
            style={{ textDecoration: "none", color: "#000",fontWeight:"500", }}
            target="_blank"
          >
            Need Help?
          </a>
          {(Object.keys(userCred_id).length !== 0 && Object.keys(AdminCred_id).length === 0 )&& (
            <>
              {" "}
              &nbsp;|&nbsp;
              <Link
                to="/MyAccount"
                className="navedecorate text-dark"
              >
                My Account
              </Link>{" "}
            </>
          )}
          { Object.keys(AdminCred_id).length === 0 && ( <>
              {/* {" "} */}
          &nbsp;|&nbsp;
          <Link to="/LoginUser" className="navedecorate">
            <a
              href=""
              onClick={handlechangeURL}
              className="text-dark"
              style={{ textDecoration: "none", fontWeight:"500", }}
            >{`${
              Object.keys(userCred_id).length === 0 ? "LogIn" : "LogOut"
            }`}</a>
          </Link> {" "}
            </>) }
        </span>

        {/* </div> */}
      </nav>
    
      {/* <h2 className="headingmain mt-4 mb-4 paddingGen">{MainHeading} </h2> */}
    
      {/* <hr className="w-100 ms-3 mb-5 line" /> */}
    </div>
  );
};

export default TopNav;
