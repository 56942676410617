import React, { useState, useEffect, useRef } from "react";
import TopNav from "./TopNav";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { AdminCredID } from "../Redux/counterSlice";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [disablePass, setDisablePass] = useState(true);
  const [emailDisable, setemailDisable] = useState(true);
  const navigate = useNavigate();
  const handleNavigate = (route) => {
    navigate(route);
  };
  const AdminCred_id = useSelector((state) => state.counter.AdminCred);
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const dispatch = useDispatch();
  const disablePassInputRef = useRef(null);
  const changePassword = () => {
    setDisablePass(false);
    disablePassInputRef.current.focus();
  };

  const changePasswordHandle = () => {
    
    // Define the request payload
    const inputValue = disablePassInputRef.current.value;
    const data = {
      admin_email: email,
      new_password: inputValue,
    };

    // Define the URL of the API endpoint
    const apiUrl = "https://cloudresumes.com/api/admin_pass_change.php";

    // Make the POST request using Axios
    return axios
      .post(apiUrl, data)
      .then((response) => {
        
        // Handle the successful response here
        toast.success(response.data.msg);
        dispatch(AdminCredID(data));
        setDisablePass(true);
        return response.data; // You can return the response data if needed
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error changing password", error);
        throw error; // Rethrow the error to be handled by the caller
      });
  };
  const handleSave = () => {
    // Perform actions with email and password
    console.log("Email:", email);
    console.log("Password:", password);
  };
  useEffect(() => {
    if (AdminCred_id) {
      setEmail(AdminCred_id.admin_email);
      setPassword(AdminCred_id.admin_password);
    }
    else
    {
      handleNavigate('/AdminLogin')
    }
  }, [AdminCred_id]);
  return (
    <div className="col-lg-10 p-0 rightSideSection" id="adminPanelRoot">
      <ToastContainer />

      <TopNav MainHeading={"Profile"} />
      {/* <div className="form-group">
        <label>Email:</label>
        <input type="email" className="form-control" value={email} onChange={handleEmailChange} />
      </div> */}
      <div className="componentContainer paddingGen">
      <div
      >
        <div className="col-lg-4 mb-3">
          <label htmlFor="Email">Email</label>
          <input
            type="Email"
            className="form-control inputfield"
            id="Email"
            placeholder="Email"
            value={email}
            disabled={emailDisable}
          />
        </div>

        <div className="col-lg-4">
          <label htmlFor="Password">Password</label>
          <input
            type="text"
            className="form-control inputfield"
            id="Password"
            placeholder="Password"
            value={password}
            onChange={handlePasswordChange}
            disabled={disablePass}
            ref={disablePassInputRef}
          />
        </div>
        <div>
          <button className="btn btn-primary w-lg-25 my-5" onClick={changePassword}>
            Change Password
          </button>
          {!disablePass && (
            <button
              className="btn btn-primary w-50 mt-5"
              onClick={changePasswordHandle}
            >
              Save
            </button>
          )}
        </div>
      </div>
      </div>

    </div>
  );
};

export default Profile;
