import { createSlice } from "@reduxjs/toolkit";

 
const storstate = {
  initialState: {
    // Define the structure of initialState if needed
  },
  UserCred: {},
  Planesubsdata: {},
  AdminCred: {},
  addexp: [],
  AddSubs: [],
  addeducation: [],
  addReferences: [],
  certifcates: [],
  skillsdata: [],
  userdatastate: [],
  image: null,
  editcasee_sub: null,
  Edit_certifcates: null,
  editcasee_xp: null,
  edit_education: null,
  edit_References: null,
  Editskillsdata: null,
  add_CareerObjective: null,
  // Leftmaue FLag
  ContactFlag: false,
  workpointer: false,
  Experiencepointer: false,
  Educationpointer: false,
  Certificationpointer: false,
  Skillspointer: false,
  Referencespointer: false,
  CareerObjectivepointer: false,
  Templatespointer: false,
  FinalizePointerFlag: false,
  waterMark: false,
  ProgressValue: 0,

  // Cv Templetes
  Cv1: false,
  Cv2: false,
  Cv3: false,
  Cv4: false,
  Cv5: false,
  Cv6: false,
  Cv7: false,
  Cv8: false,
};

const counterSlice = createSlice({
  name: "Contact",
  initialState: storstate,
  reducers: {
    GetContactDetails: (state, action) => {
      state.initialState = action.payload;
    },
    imageupload: (state, action) => {
      state.image = action.payload;
    },
    AddExperience: (state, action) => {
      state.addexp = action.payload;
    },
    AddSubscription: (state, action) => {
      state.AddSubs = action.payload;
    },
    EditExperience: (state, action) => {
      state.editcasee_xp = action.payload;
    },
    EditSubs: (state, action) => {
      state.editcasee_sub = action.payload;
    },
    EditEducation: (state, action) => {
      state.edit_education = action.payload;
    },
    EditReferences: (state, action) => {
      state.edit_References = action.payload;
    },
    Educationstore: (state, action) => {
      state.addeducation = action.payload;
    },
    Referencesdatas: (state, action) => {
      state.addReferences = action.payload;
    },
    CerticateData: (state, action) => {
      state.certifcates = action.payload;
    },
    EditCertifications: (state, action) => {
      state.Edit_certifcates = action.payload;
    },
    Skillsdata: (state, action) => {
      state.skillsdata = action.payload;
    },
    EditSkills: (state, action) => {
      state.Editskillsdata = action.payload;
    },
    CareerObjectivestore: (state, action) => {
      state.add_CareerObjective = action.payload;
    },
    ContactFlagpointer: (state, action) => {
      state.ContactFlag = action.payload;
    },
    workpointerFlagpointer: (state, action) => {
      state.workpointer = action.payload;
    },
    EducationpointerFlagpointer: (state, action) => {
      state.Educationpointer = action.payload;
    },
    CertificationpointerFlagpointer: (state, action) => {
      state.Certificationpointer = action.payload;
    },
    SkillspointerFlagpointer: (state, action) => {
      state.Skillspointer = action.payload;
    },
    ReferencespointerFlagpointer: (state, action) => {
      state.Referencespointer = action.payload;
    },
    CareerObjectivepointerFlagpointer: (state, action) => {
      state.CareerObjectivepointer = action.payload;
    },
    TemplatespointerFlagpointer: (state, action) => {
      state.Templatespointer = action.payload;
    },
    FinalizedPointer: (state, action) => {
      state.FinalizePointerFlag = action.payload;
    },
    Progress: (state, action) => {
      state.ProgressValue = action.payload;
    },

    // CV Flag
    ShowCV1: (state, action) => {
      state.Cv1 = action.payload;
    },
    ShowCV2: (state, action) => {
      state.Cv2 = action.payload;
    },
    ShowCV3: (state, action) => {
      state.Cv3 = action.payload;
    },
    ShowCV4: (state, action) => {
      state.Cv4 = action.payload;
    },
    ShowCV5: (state, action) => {
      state.Cv5 = action.payload;
    },
    ShowCV6: (state, action) => {
      state.Cv6 = action.payload;
    },
    ShowCV7: (state, action) => {
      state.Cv7 = action.payload;
    },
    ShowCV8: (state, action) => {
      state.Cv8 = action.payload;
    },
    AdminCredID: (state, action) => {
      state.AdminCred = action.payload;
    },
    UserCredID: (state, action) => {
      state.UserCred = action.payload;
    },
    Planesubs: (state, action) => {
      state.Planesubsdata = action.payload;
    },
    setWaterMark: (state, action) => {
      state.waterMark = action.payload;
    },
    userdata: (state, action) => {
      state.userdatastate = action.payload;
    },
  },
});

// const persistConfig = {
//   key: "root",
//   storage,
//   whitelist: ['UserCred'],  
// };
// const persistedReducer = persistReducer(persistConfig, counterSlice.reducer);
// const store = configureStore({
//   reducer: persistedReducer,
// });

// const persistor = persistStore(store); 
// export { store, persistor };
export const {
  userdata,
  UserCredID,
  setWaterMark,
  Planesubs,
  AdminCredID,
  ShowCV1,
  ShowCV2,
  ShowCV3,
  ShowCV4,
  ShowCV5,
  ShowCV6,
  ShowCV7,
  ShowCV8,
  Progress,
  workpointerFlagpointer,
  EducationpointerFlagpointer,
  CertificationpointerFlagpointer,
  SkillspointerFlagpointer,
  ReferencespointerFlagpointer,
  CareerObjectivepointerFlagpointer,
  TemplatespointerFlagpointer,
  FinalizedPointer,
  ContactFlagpointer,
  GetContactDetails,
  imageupload,
  AddExperience,
  EditExperience,
  EditEducation,
  EditReferences,
  Educationstore,
  Referencesdatas,
  CerticateData,
  EditCertifications,
  Skillsdata,
  EditSkills,
  CareerObjectivestore,
  AddSubscription,
  EditSubs,
} = counterSlice.actions;

export default counterSlice.reducer;
