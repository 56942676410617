import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";



// import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';

const SignUp = () => {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const navigate = useNavigate();
  const handleNavigate = (route) => {
    navigate(route);
  };
  const handleInputChange = (event) => {
    
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  //   const handleSignup = () => {
  //     // Handle signup logic here using the formData object
  //     console.log('Signup submitted', formData);
  //   };

  const handleSignup = () => {
    debugger
    // Create a data object from the form data
    if(formData.username !== '' && formData.email !== '' && formData.password !== '' )
    {
      if (formData.email.includes('@'))
      {
        const data = {
          user_name: formData.username,
          user_email: formData.email,
          user_password: formData.password,
        };
        axios
          .post("https://cloudresumes.com/api/register_user.php", data)
          .then((response) => {
            
            if (response.status === 200) {
              if (response.data) {
                if (response.data.msg !== "Something went wrong") {
                  toast.success('SignUp successfull, Loading...');
                  handleNavigate("/LoginUser");
                } else {
                  toast.warning(response.data.msg);
                }
              }
            }
            // Perform any additional actions upon successful signup
          })
          .catch((error) => {
            toast.warning(error);
            // Handle errors here
          });
      }
      else
      {
        toast.error('Invalid email address');
      }
   
    }
    else
    {
      toast.error('Please fill all the required fields');
    }

  };

  return (
    <div className="container-fluid d-flex justify-content-center align-items-center" style={{height: '100vh'}}>
      {/* <!-- Centered Logo --> */}
      <ToastContainer />

      <div className="text-center col-md-4 loginContainer form-font ">
        <div className="mx-3 side-menu-logo">
          <img
            src="./logo.png"
            alt="Logo"
            className="mb-3"
            style={{ maxWidth: "45%" }}
          />
        </div>
        <div className="card mx-3 cards">
          <div className="card-body">
          <h1 style={{fontSize:"20px",color:"#ff7900"}}>Enter Your Details</h1>

            <div className="form-group mb-4">
              {/* <label for="username">Username</label> */}
              <input
                type="text"
                className="form-control inputfield"
                id="username"
                name="username"
                placeholder="Enter your Name"
                value={formData.username}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group mb-4">
              {/* <label for="username">Username</label> */}
              <input
                type="text"
                className="form-control inputfield"
                id="email"
                name="email"
                placeholder="Enter your Email"
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group mb-4">
              {/* <label for="username">Username</label> */}
              <input
                type="password"
                className="form-control inputfield"
                id="password"
                name="password"
                placeholder="Enter your Password"
                value={formData.password}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group mb-4">
              {/* <label for="password">Password</label> */}
              <input
                type="password"
                className="form-control inputfield"
                id="confirmPassword"
                name="confirmPassword"
                placeholder="Confirm Password"
                value={formData.confirmPassword}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group mb-4 text-start">
              {/* <label for="password">Password</label> */}
            </div>

            <div className="form-group mb-4">
              {/* <label for="password">Password</label> */}
              <button
                type="button"
                className="btn btn-delete btn-block w-100 mt-3"
                onClick={handleSignup}
              >
                Submit
              </button>
            </div>
            <p className="d-flex justify-content-center">
              Already registered?  <p
                style={{
               
                  color: "#ff7900",
                  textDecoration: "none",
                  cursor: "pointer",
                  marginLeft: "5px",
                }}
                onClick={() => {
                  handleNavigate("/LoginUser");
                }}
              >
                {" "}
                Login
              </p>
              </p>
              <p
                className="mt-2"
                style={{
                  color: "#ff7a00",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleNavigate("/");
                }}
              >
                {" "}
                - Back to Resume Builder -
              </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;






