import React, { useEffect, useState } from "react";
// import './CustomComponent.css'; // Import the custom CSS file for the component
// import { IoMdCreate, IoMdTrash } from 'react-icons/io';
import { IoIosAddCircleOutline } from "react-icons/io";
import { MdEdit, MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { EditSkills } from "../Redux/counterSlice";
import {
  SkillspointerFlagpointer,
  Progress,
  Skillsdata,
} from "../Redux/counterSlice";
import { ToastContainer, toast } from "react-toastify";

import TopNav from "./TopNav";
import { makeApiRequest } from "./Services";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';


const Skills = () => {
  const navigate = useNavigate();
  
  const dispatch = useDispatch();

  const [skills_data, setSkillsdata] = useState([]);
  const Skillspointer = useSelector((state) => state.counter.Skillspointer);
  const Contactdata = useSelector((state) => state.counter.initialState);
  const Skills_data = useSelector((state) => state.counter.skillsdata);
  const handleNavigate = (route) => {
    if(Skills_data.length === 5 || Skills_data.length > 6)
    {
      toast.error("You can not add more than 5 skills");
    }
    else
    {
      navigate(route);
    }
  };
  useEffect(() => {
    // Perform navigation here
    navigate("/Skills"); // Replace '/destination' with the actual path you want to navigate to
  }, []);
  useEffect(() => {
    
    if (Skills_data) {
      setSkillsdata(Skills_data);
    }
  }, [Skills_data]);

  const EditSkillhandler = (event) => { 
    dispatch(EditSkills(event));
    navigate("/AddSkill");
  };
  function generateRandomId(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomId = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomId += characters.charAt(randomIndex);
    }

    return randomId;
  }
  const HandleNextStep = () => {
    
    // if(Skills_data.length)
    // {
    if (!Skillspointer) {
      dispatch(Progress(60));
    }
    dispatch(SkillspointerFlagpointer(true));

    navigate("/CareerObjective");
    // }
    // else{
    //   toast.error("Add Skills");
    // }
  };
  const AddSuggestions = (Skill_Name) => { 
    if(Skills_data.length === 5 || Skills_data.length > 6)
    {
      toast.error("You can not add more than 5 skills");
    }
    else if(Skills_data.length < 5)
    {
      const randomId = generateRandomId(Skill_Name.length);
      const obj = {
        id: randomId,
        SkillName: Skill_Name,
      };
      const newdata = Skills_data.findIndex((x) => x.SkillName === Skill_Name);
      if (newdata !== -1) {
        toast.success("Skill has already added.");
      } else {
        const NewCopy = [...Skills_data];
        NewCopy.push(obj);
        dispatch(Skillsdata(NewCopy));
      }
    }
    else{}
  };
  const DeleteRow = (id) => {
    debugger
    const dataCopy = Skills_data.map((value) => {
      if (value.id === id) {
        return { ...value, activity: "delete" };
      }
      return value;
    });
    const filteredData = dataCopy.filter((val) => val.activity !== "delete");
    dispatch(Skillsdata(filteredData));
    const requestBody = {
      resume_id: Contactdata.resume_id ? Contactdata.resume_id : 0 ,
      methodId: 'skills',
      skill_id: +id
    };
    makeApiRequest(requestBody)
  };
  return (
    <div className="col-lg-10 p-0 rightSideSection">
      <ToastContainer />

      <TopNav MainHeading={"Add your most relevant skills *Tip: we recommend 5 skills"} />
      <div className="componentContainer paddingGen">
      <div className="row mt-3 mb-3 ">
          <div className="col-lg-12">
            <h2 className="headingmain mt-4 mb-4">Add your most relevant skills *Tip: we recommend 5 skills</h2>
          </div>
        </div>
        <div
          className="border border-dark mb-5"
          style={{
            borderRadius: "10px",
            borderColor: "rgb(126, 125, 130)",
          }}
        >
          <div className="row  ">
            <div className="justify-content-center  col-lg-12">
              <div className="p-2">
                <h6>Skills</h6>
                <div className="card mb-0">
                  <div className="card-body">
                    {skills_data &&
                      skills_data.map((val) => (
                        <div className="row mb-2" id={val.id} key={val.id}>
                          <div className="col-md-6 text-right">
                            <p
                              className="card-text text-sm-start"
                              id={val.id}
                              key={val.id}
                            >
                              {val?.SkillName}
                            </p>
                          </div>
                          <div className="col-md-6 text-lg-end">
                            <MdEdit
                              className="mr-2 edit-icon"
                              size={24}
                              id={val.id}
                              key={val.id}
                              onClick={() => EditSkillhandler(val.id)}
                            />
                            <MdDelete
                              size={24}
                              onClick={() => DeleteRow(val.id)}
                              className="delete-icon"
                            />
                          </div>
                        </div>
                      ))}
                    {/* <div className="row mt-1">
                    <div className="col-md-12">
                      <p className="card-text text-sm-start">
                            10/10/2010
                      </p>
                    </div>
                  </div> */}
                  </div>
                </div>
              </div>
              <div className="p-2">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <button
                        type="button"
                        class="btn btn-outline-ligh"
                        onClick={() => handleNavigate("/AddSkill")}
                      >
                        <IoIosAddCircleOutline size={24} /> Add Skills
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="justify-content-center  col-lg-12">
              <div className="p-2">
                <h6>Suggestions</h6>




                <div className="card cards-suggestion">
                  <div className="card-body d-flex flex-wrap gap-4 shadow">
                  <Swiper navigation={true} modules={[Navigation]}  slidesPerView={2}
        spaceBetween={30} className="mySwiper" style={{paddingLeft:"100px"}}>
        <SwiperSlide>
          <section className="General" style={{borderRadius:"8px"}}> 
                    <b>General</b><br/>
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Adaptability")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Adaptability
                    <br />
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Leadership")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Leadership
                    <br />
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Time Management")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Time Management
                    <br />
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Positive Attitude")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Positive Attitude
                    <br />
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Critical Thinking")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Critical Thinking
                    <br />
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Conflict Resolution")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Conflict Resolution
                    <br />
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Hard Working")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Hard Working
                    <br />
                    </section>
                    </SwiperSlide>
        <SwiperSlide>
          <section className="Business" style={{borderRadius:"8px"}}>
                    <b>Business</b><br/>
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Communication Skills")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Communication Skills
                    <br />
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Problem-Solving")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Problem-Solving
                    <br />
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Project Management")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Project Management
                    <br />
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Data Analysis")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Data Analysis
                    <br />
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Teamwork")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Teamwork
                    <br />
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Customer Relationship Management (CRM)")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Customer Relationship Management (CRM)
                    <br />
                    </section>
                    </SwiperSlide>
        <SwiperSlide>
          <section className="Technology" style={{borderRadius:"8px"}}>
                    <b>Technology</b><br/>
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Programming & Coding")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Programming and Coding
                    <br />
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Cybersecurity")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Cybersecurity
                    <br />
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Software Development")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Software Development
                    <br />
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Cloud Computing")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Cloud Computing
                    <br />
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Network Administration")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Network Administration
                    <br />
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Agile/Scrum Methodology")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Agile/Scrum Methodology
                    </section>
                    </SwiperSlide>
                    <SwiperSlide> 
                      <section className="Healthcare " style={{borderRadius:"8px"}}>
                    <b>Healthcare</b><br/>
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Patient Care")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Proatient Care
                    <br />
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Medical Records Management")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Medical Records Management
                    <br />
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Clinical Procedures")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Clinical Procedures
                    <br />
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Health Information Systems")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Health Information Systems
                    <br />
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Medical Terminology")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Medical Terminology
                    <br />
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Interpersonal Skills")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Interpersonal Skills
                    </section></SwiperSlide>
<SwiperSlide> <section className="Education " style={{borderRadius:"8px"}}>
                    <b>Education</b><br/>
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Curriculum Development")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Curriculum Development
                    <br />
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Classroom Management")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Classroom Management
                    <br />
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Lesson Planning")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Lesson Planning
                    <br />
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Assessment and Evaluation")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Assessment and Evaluation
                    <br />
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Special Education")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Special Education
                    <br />
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Technology Integration")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Technology Integration
                    </section></SwiperSlide>
<SwiperSlide> 
                    <section className="Legal " style={{borderRadius:"8px"}}>
                    <b>Legal</b><br/>
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Legal Research")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Legal Research
                    <br />
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Contract Law")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Contract Law
                    <br />
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Litigation")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Litigation
                    <br />
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Legal Writing")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Legal Writing
                    <br />
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Case Management")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Case Management
                    </section></SwiperSlide>
<SwiperSlide>   <section className="Retail" style={{borderRadius:"8px"}}>
                    <b>Retail</b><br/>
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Customer Service")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Customer Service
                    <br />
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Sales Techniques")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Sales Techniques
                    <br />
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Inventory Management")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Inventory Management
                    <br />
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Visual Merchandising")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Visual Merchandising
                    <br />
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Cash Handling")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Cash Handling
                    <br />
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Loss Prevention")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Loss Prevention
                    </section></SwiperSlide>
<SwiperSlide>   
                    <section className="Finance " style={{borderRadius:"8px"}}>
                    <b>Finance</b><br/>
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Financial Analysis")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Financial Analysis
                    <br />
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Accounting Software")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Accounting Software
                    <br />
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Risk Management")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Risk Management
                    <br />
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Investment Analysis")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Investment Analysis
                    <br />
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Financial Planning")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Financial Planning
                    <br />
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Regulatory Compliance")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Regulatory Compliance
                    </section></SwiperSlide>
<SwiperSlide> <section className="Real Estate " style={{borderRadius:"8px"}}>
                    <b>Real Estate</b><br/>
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Market Anaylsis")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Market Anaylsis
                    <br />
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Property Management")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Property Management
                    <br />
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Negotiation Skills")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Negotiation Skills
                    <br />
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Real Estate Law")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Real Estate Law
                    <br />
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Market and Promotion")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp; Market and Promotion
                    <br />
                    <IoIosAddCircleOutline
                      onClick={() => AddSuggestions("Property Valuation")}
                      size={18}
                      style={{ color: "#ff7900", cursor: "pointer" }}
                    />
                    &nbsp;Property Valuation
                    </section></SwiperSlide>
      </Swiper>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end my-5">
          <button
            class="btn btn-secondary bg-white w-25 me-3 btn-back"
            onClick={() => navigate("/certification")}
          >
            Back
          </button>
          <button class="btn btn-delete w-25" onClick={HandleNextStep}>
            {`    `}Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Skills;

