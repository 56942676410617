import React, { useState, useEffect } from "react";
// import './CustomComponent.css'; // Import the custom CSS file for the component
// import { IoMdCreate, IoMdTrash } from 'react-icons/io';
import AddNewExp from "./AddNewExp";
import { IoIosAddCircleOutline } from "react-icons/io";
import { MdEdit, MdDelete } from "react-icons/md";
import TopNav from "../Components/TopNav";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AddSubscription, EditSubs } from "../Redux/counterSlice";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

let flag = 0;
const Subscriptionadmin = () => {
  const [workExp, setWorkExpt] = useState([]);
  const navigate = useNavigate();
  const AddSubs = useSelector((state) => state.counter.AddSubs);
  const AdminCred_id = useSelector((state) => state.counter.AdminCred);
  const userdatastate = useSelector((state) => state.counter.userdatastate);

  const dispatch = useDispatch();
  const [subscriptions, setSubscriptions] = useState([]);

  const handleNavigate = (route) => {
    navigate(route);
  };
  const fetchData = async () => {
    try {
      const requestBody = {
        admin_token: AdminCred_id.admin_token,
      };
      const response = await axios.post(
        "https://cloudresumes.com/api/fetch_subscriptions.php",
        requestBody
      );
      debugger;
      if (response.data.data !== null && response.data.data !== undefined) {
        const AllSubData = response.data.data.map(function (Subscription) {
          // Modify keys as needed
          return {
            id: Subscription.id,
            SubscriptionTitle: Subscription.name,
            SubscriptionCost: Subscription.price,
            SubscriptionValidity: Subscription.duration,
            summary: Subscription.description,
          };
        });
        setWorkExpt(AllSubData);
        dispatch(AddSubscription(AllSubData));
      }
    } catch (error) {}
  };
  useEffect(() => {
    debugger;
    if (AdminCred_id) {
      fetchData();
    } else {
      handleNavigate("/AdminLogin");
    }
  }, [AdminCred_id]);
  useEffect(() => {
    if (AddSubs) {
      setWorkExpt(AddSubs);
    }
  }, [AddSubs]);
  const [addexpflag, setaddexpflag] = useState(true);
  const handlereditcase = (event) => {
    const getid = event;
    dispatch(EditSubs(getid));
    handleNavigate("/AddSubscriptionadmin");
  };

  const CheckSubscription = (id) => {
    debugger;
    if (workExp && userdatastate) {
      const checksubarray = workExp.filter((x) => x.id === id);
      const checkSubscription = userdatastate.filter(
        (x) => x.subscription === checksubarray[0].SubscriptionTitle
      );
      if (
        checkSubscription !== null &&
        checkSubscription !== undefined &&
        checkSubscription.length > 0
      ) {
        toast.error(
          `This subscription is already subscribed by ${checkSubscription.length} people`
        );
        return false;
      } else {
        return true;
      }
    }
  };

  const handlerDeletecase = async (id) => {
    debugger;
    const checkValidation = CheckSubscription(id);
    if (checkValidation) {
      const requestBody = {
        admin_id: 1,
        admin_token: AdminCred_id.admin_token,
        id: +id,
      };
      const response = await axios.post(
        "https://cloudresumes.com/api/delete_subscription.php",
        requestBody
      );
      if (response.data.status === "success") {
        setTimeout(() => {
          fetchData();
        }, 3000);
      }
      if (response.data.data !== null && response.data.data !== undefined) {
        const AllSubData = response.data.data.map(function (Subscription) {
          // Modify keys as needed
          return {
            id: Subscription.id,
            SubscriptionTitle: Subscription.name,
            SubscriptionCost: Subscription.price,
            SubscriptionValidity: Subscription.duration,
            summary: Subscription.description,
          };
        });
        setWorkExpt(AllSubData);
        dispatch(AddSubscription(AllSubData));
      }
    }
  };
  return (
    <div className="col-lg-10 p-0 rightSideSection">
      <TopNav MainHeading={"Subscription"} />
      <ToastContainer />

      {addexpflag ? (
        <>
          <div
            className="border border-dark mb-5 pl-1 pr-1 "
            style={{
              borderRadius: "0.5em",
              borderColor: "rgb(126, 125, 130)",
              marginLeft: "16px",
              marginRight: "30px",
            }}
          >
            {workExp.map((val) => (
              <div className="row " id={val.id} key={val.id}>
                <div className="justify-content-center ">
                  <div className="mt-3 mx-2">
                    <div className="card mb-0">
                      <div className="card-body">
                        <div className="row ">
                          <div className="col-md-6 text-right">
                            <h4 className="card-title text-sm-start">{`${val.SubscriptionTitle} - $${val.SubscriptionCost} - ${val.SubscriptionValidity}days`}</h4>
                          </div>
                          <div className="col-md-6 text-lg-end">
                            <MdEdit
                              size={24}
                              className="mr-2 edit-icon"
                              id={val.id}
                              key={val.id}
                              onClick={() => handlereditcase(val.id)}
                            />
                            <MdDelete
                              size={24}
                              className="delete-icon"
                              key={val.id}
                              onClick={() => handlerDeletecase(val.id)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="row mb-4">
              <div className="justify-content-center ">
                <div className="mt-4 mx-2">
                  <div className="card mb-5v">
                    <div className="card-body">
                      <div className="row ">
                        <button
                          type="button"
                          class="btn btn-outline-ligh"
                          onClick={() =>
                            handleNavigate("/AddSubscriptionadmin")
                          }
                        >
                          <IoIosAddCircleOutline size={24} /> Subscription
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <AddNewExp />
      )}
    </div>
  );
};

export default Subscriptionadmin;
