import React, { useState, useEffect } from "react";
import axios from "axios";
import TopNav from "./TopNav";
import { FaCheck } from "react-icons/fa";
// import { loadStripe } from "@stripe/stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Planesubs, UserCredID } from "../Redux/counterSlice";
import { Elements } from "@stripe/react-stripe-js";
import CustomModal from "./CustomModal";
import { loadStripe } from "@stripe/stripe-js";
import { ToastContainer, toast } from "react-toastify";
import parse from "html-react-parser";

const stripePromise = loadStripe(
  "pk_test_51NgdivJrvqP6pDNWH7AeAMkhlLjuUgJipnLdxdMYJJrgUhDOq27iQkILBgDinbAZYFNBePtlbM8LPk5DJXDYSXdW008xqkgRPP"
);
let subDate;
let logindata = {};
const SubscriptionProfile = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [stripe, setStripe] = useState(null);
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const UserCreddata = useSelector((state) => state.counter.UserCred);
  const SubscriptionData = useSelector((state) => state.counter.AddSubs);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleNavigate = (route) => {
    navigate(route);
  };
  const [count, setcount] = useState(0);
  useEffect(() => {
    logindata = JSON.parse(localStorage.getItem("UserCredID"));
    // subDate = JSON.parse(localStorage.getItem("PlanesubsTitle"));
    if (!count) {
      fetchData();
      setcount(count + 1);
    }
  }, []);
  const fetchData = async () => {
    try {
      const requestBody = {
        user_token: UserCreddata.token ? UserCreddata.token : logindata.token,
        // admin_token: UserCreddata.token
      };

      const response = await axios.post(
        "https://cloudresumes.com/api/fetch_subscriptions.php",
        requestBody
      );

      if (response.data.data !== null && response.data.data !== undefined) {
        setSubscriptions(response.data.data); // Update state with the response data
        subDate = response.data.data.filter((x) => x.name === subDate);
        setLoading(false);
      } // Set loading to false after data is fetched
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
      setLoading(false); // Set loading to false in case of errors
    }
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSave = () => {
    // Perform actions with email and password
    console.log("Email:", email);
    console.log("Password:", password);
  };
  const handleChooseButtonClick = (id, title, cost) => {
    setSelectedSubscription({ id, title, cost });
    const dataPlanesubs = {
      PlanesubsID: id,
      PlanesubsTitle: title,
      PlanesubsCost: cost,
    };
    localStorage.setItem("PlanesubsTitle", JSON.stringify(title));
    dispatch(Planesubs(dataPlanesubs));
    OpenModal();
    // handleNavigate('/StripePaymentForm')
  };
  const [showmodal, setShowModal] = useState(false);
  const OpenModal = () => {
    setShowModal(true);
  };
  const CloseModal = () => {
    setShowModal(false);
  };
  const CloseModalAfterfayment = (response, title) => {
    toast.success(response);
    const datas = { ...UserCreddata };
    const usierIndfo = {
      msg: datas.msg,
      subscription: title,
      user_id: datas.user_id,
      token: datas.token,
    };

    dispatch(UserCredID(usierIndfo));
    localStorage.setItem("UserCredID", JSON.stringify(usierIndfo));
    setTimeout(() => {
      setShowModal(false);
      handleNavigate("/Contact");
    }, 2000);
  };
  const cancelSubscription = async () => {
    debugger;
    const body = {
      user_id: +UserCreddata.user_id,
      user_token: UserCreddata.token,
    };
    try {
      const response = await axios.post(
        "https://cloudresumes.com/api/cancel_subscription.php",
        body
      );
      debugger;
      if (response.data.status === "success") {
        const usierIndfo = {
          msg: UserCreddata.msg,
          subscription: "none",
          user_id: UserCreddata.user_id,
          token: UserCreddata.token,
          user_email: UserCreddata.user_email,
          subscription_days: " ",
        };
        dispatch(UserCredID(usierIndfo));
        localStorage.setItem("UserCredID", JSON.stringify(usierIndfo));
        toast.success("Your subscription has canceled");
        handleNavigate("/Contact");
      }
      // Handle the response data here
      console.log("Response:", response.data);
      return response.data; // Return the response data if needed
    } catch (error) {
      // Handle errors here
      console.error("Error:", error);
      throw error; // Throw the error for handling in the calling code if needed
    }
  };
  const checkoutmethod = async () => {
    debugger;
    const body = {
      user_id: +UserCreddata.user_id,
      user_token: UserCreddata.token,
    };
  
    try {
      const response = await axios.post('https://cloudresumes.com/api/checkout.php', body);
      debugger;
      console.log('Response:', response.data);
      // Handle the response data here
    } catch (error) {
      console.error('Error:', error);
      // Handle errors here
    }
  };

  const handleClick = (name) => {
    debugger;
    if(name ==="one")
    {
      const url = 'https://buy.stripe.com/8wM9Ed4Tl3Xk6qs6ot'; // Replace this with your desired URL
      window.open(url, '_blank');
    }      
    if(name ==="two")
    {
      const url = 'https://buy.stripe.com/eVa6s171t8dA3eg8wA'; // Replace this with your desired URL
      window.open(url, '_blank');
    }      
    if(name ==="three")
    {
      const url = 'https://buy.stripe.com/8wM3fPclNalI9CE6ou'; // Replace this with your desired URL
      window.open(url, '_blank');
    }      
          
    
};
  return (
    <div className="col-lg-12 p-0">
      <ToastContainer />
      <TopNav MainHeading={"Subscription Plans"} />
      <div className="componentContainer paddingGen shadow" style={{padding:"47px", boxShadow:"1px solid black"}}>
        <h2 className="text-center" style={{color: '#FF7900', fontSize: '26px'}}>Download now: Unlock Career Potential</h2>
        <h3 className="text-center" style={{color: 'grey', fontSize: '18px', fontWeight: '500', marginTop: '10px',marginBottom:"10px"}}>Select Subscription and Download your Resume</h3>
        <div
          className=""
          style={{
            borderRadius: "10px",
            borderColor: "rgb(126, 125, 130)",
          }}
        >
          <div
            className="d-lg-flex justify-content-between p-2"
            style={{
              borderRadius: "10px",
            }}
          >
                <div className="cardnew m-4 shadow p-5 mb-3" style={{paddingTop: "0%"}}>
                  <span className="d-flex justify-content-center">
                  <p className="popularBadge">MOST POPULAR</p>
                  </span>
                  <div className="card-rows justify-content-center">
                    <h5 className="bold mb-0 priceTitle">A$6.50</h5>
                  </div>

                  <div className="card-rows mb-2 d-flex flex-column align-items-center">
                    <h2 className="subscriptionTitle text-center">
                      7 Days trial subscription
                    </h2>
                    <p style={{fontSize: '13px', color: 'grey'}}>Auto renews to monthly payment</p>
                  </div>
                  <hr style={{ width: "100%", margin: "20px 0" }} />
                  <div className="card-rows">
                    <p className="mb-0"></p>
                  </div>
                  <div className="card-rows">
                    <p className="mb-0">
                      <FaCheck style={{ color: "#ff7900", fontSize: "15px" }} />&nbsp;
                      Unlimited Designs
                    </p>
                  </div>
                  <div className="card-rows">
                    <p className="mb-0">
                      <FaCheck style={{ color: "#ff7900", fontSize: "15px" }} />&nbsp;
                      Unlimited Downloads
                    </p>
                  </div>
                  <div className="card-rows">
                    <p className="mb-0">
                      <FaCheck style={{ color: "#ff7900", fontSize: "15px" }} />&nbsp;
                      Unlimited QR Codes
                    </p>
                  </div>
                  <div className="card-rows">
                    <p className="mb-0">
                      {" "}
                      <FaCheck
                        style={{ color: "#ff7900", fontSize: "15px" }}
                      />
                       &nbsp;&nbsp;Cancel Anytime
                    </p>
                  </div>
                  <div className="card-rows">
                    <p className="mb-0">
                      {" "}
                      <FaCheck
                        style={{ color: "#ff7900", fontSize: "15px" }}
                      />
                       &nbsp;&nbsp;-20% off Monthly Rate
                    </p>
                  </div>
                  <div className="card-rows mb-auto m-lg-0">
                    <h5 className="bold mb-0">
                    </h5>
                  </div>
                  <div className="buttonrow mt-3">
                    <button
                      className="subscription-buttons subscription-buttonsOne"
                      style={{bottom: '48px'}}
                      onClick={() => {
                        debugger;
                        handleClick("one");
                      }}
                      // onClick={OpenModal}
                    >
                      Select
                    </button>
                  </div>
                </div>
                <div className="cardnew m-4 bg-clrs p-5 shadow">
                <span className="d-flex justify-content-center">
                  </span>
                  <div className="card-rows justify-content-center">
                    
                    <h5 className="bold mb-0 priceTitle" style={{marginTop: '50px'}}>A$34.00</h5>
                  </div>

                  <div className="card-rows mb-2 justify-content-center">
                    <h2 className="subscriptionTitle" style={{paddingBottom: '35px'}}>
                    
                    Monthly Subscription
                    </h2>
                  </div>
                  <hr style={{ width: "100%", margin: "20px 0" }} />
                  <div className="card-rows">
                    <p className="mb-0"></p>
                  </div>
                  <div className="card-rows">
                    <p className="mb-0">
                      <FaCheck style={{ color: "#ff7900", fontSize: "15px" }} />&nbsp;
                      Unlimited Designs
                    </p>
                  </div>
                  <div className="card-rows">
                    <p className="mb-0">
                      <FaCheck style={{ color: "#ff7900", fontSize: "15px" }} />&nbsp;
                      Unlimited Downloads
                    </p>
                  </div>
                  <div className="card-rows">
                    <p className="mb-0">
                      <FaCheck style={{ color: "#ff7900", fontSize: "15px" }} />&nbsp;
                      Unlimited QR Codes
                    </p>
                  </div>
                  <div className="card-rows">
                    <p className="mb-0">
                      {" "}
                      <FaCheck
                        style={{ color: "#ff7900", fontSize: "15px" }}
                      />
                       &nbsp;&nbsp;Cancel Anytime
                    </p>
                  </div>

                  <div className="card-rows mb-auto m-lg-0">
                    <h5 className="bold mb-0">
                      {/* <FaCheck style={{ color: "green", fontSize: "15px" }} />{" "}
                      {` Auto renews to monthly payment ($34)`} */}
                    </h5>
                  </div>
                  <div className="buttonrow mt-3">
                    <button
                      className="subscription-buttons "
                      onClick={() => {
                        debugger;
                        handleClick("two");
                      }}
                      // onClick={OpenModal}
                    >
                      Select
                    </button>
                  </div>
                </div>
                <div className="cardnew m-4 bg-clrs shadow p-5">
                <span className="d-flex justify-content-center">
                  {/* <p className="popularBadge" style={{color: 'black'}}>BEST SAVING</p> */}
                  </span>
                  <div className="card-rows justify-content-center">
                    
                    <h5 className="bold mb-0 priceTitle" style={{marginTop: '50px'}}>A$207.00</h5>
                    {/* <h5 className="bold mb-0">{` A$${value.price} For ${value.duration} Days`}</h5> */}
                  </div>

                  <div className="card-rows mb-2 justify-content-center">
                    <h2 className="subscriptionTitle" style={{paddingBottom: '35px'}}>
                    
                    Yearly Subscription
                      {/* {` A$${value.price} For ${value.duration} Days`} */}
                    </h2>
                  </div>
                  <hr style={{ width: "100%", margin: "20px 0" }} />
                  <div className="card-rows">
                    <p className="mb-0"></p>
                  </div>
                  <div className="card-rows">
                    <p className="mb-0">
                      <FaCheck style={{ color: "#ff7900", fontSize: "15px" }} />&nbsp;
                      Unlimited Designs
                    </p>
                  </div>
                  <div className="card-rows">
                    <p className="mb-0">
                      <FaCheck style={{ color: "#ff7900", fontSize: "15px" }} />&nbsp;
                      Unlimited Downloads
                    </p>
                  </div>
                  <div className="card-rows">
                    <p className="mb-0">
                      <FaCheck style={{ color: "#ff7900", fontSize: "15px" }} />&nbsp;
                      Unlimited QR Codes
                    </p>
                  </div>
                  <div className="card-rows">
                    <p className="mb-0">
                      {" "}
                      <FaCheck
                        style={{ color: "#ff7900", fontSize: "15px" }}
                      />
                       &nbsp;&nbsp;Cancel Anytime
                    </p>
                  </div>
                  <div className="card-rows">
                    <p className="mb-0">
                      {" "}
                      <FaCheck
                        style={{ color: "#ff7900", fontSize: "15px" }}
                      />
                       &nbsp;&nbsp;-20% off Monthly Rate
                    </p>
                  </div>

                  <div className="card-rows mb-auto m-lg-0">
                    <h5 className="bold mb-0">
                      {/* <FaCheck style={{ color: "green", fontSize: "15px" }} />{" "}
                      {` Auto renews to monthly payment ($34)`} */}
                    </h5>
                  </div>
                  <div className="buttonrow mt-3">
                    <button
                      className="subscription-buttons"
                      onClick={() => {
                        debugger;
                        handleClick("three");
                      }}
                      // onClick={OpenModal}
                    >
                      Select
                    </button>
                  </div>
                </div>
          
          </div>
        </div>
        <div className="d-flex justify-content-end my-5">
          <button
            class="btn btn-back w-25 backResumesBuilder"
            onClick={() => handleNavigate("/Contact")}
          >
            Back to Resume Builder
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionProfile;
