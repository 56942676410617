import React, { useState } from 'react';
import axios from 'axios'; // You'll need to install axios for making API requests
import { CardElement,Elements, useStripe, useElements } from '@stripe/react-stripe-js';

function SubscriptionComponent() {
    const stripe = useStripe();
    const elements = useElements();
  
    const [paymentError, setPaymentError] = useState(null);
    const [paymentSuccess, setPaymentSuccess] = useState(null);
  
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      if (!stripe || !elements) {
        return;
      }
  
      const cardElement = elements.getElement(CardElement);
  
      try {
        const { paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
        });
  
        // Here, you would typically send the paymentMethod.id to your server
        // for actual payment processing and handle the response accordingly.
        // For this example, we'll just simulate success.
        
        setPaymentSuccess('Payment successful!');
      } catch (error) {
        setPaymentError(error.message);
      }
    };
  
    return (
      <div>
        <h2>Stripe Payment</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Card Number</label>
            <CardElement
              options={{
                style: {
                  base: {
                    fontSize: '16px',
                    color: '#424770',
                    '::placeholder': {
                      color: '#aab7c4',
                    },
                  },
                  invalid: {
                    color: '#9e2146',
                  },
                },
              }}
              required
            />
          </div>
          <div className="form-group">
            <label>Expiration Date (MM/YY)</label>
            {/* Add an input for the expiration date */}
          </div>
          <div className="form-group">
            <label>CVC</label>
            {/* Add an input for the CVC code */}
          </div>
          <div className="form-group">
            <label>Cardholder Name</label>
            {/* Add an input for the cardholder name */}
          </div>
  
          <button type="submit" className="btn btn-primary">
            Pay
          </button>
        </form>
  
        {paymentError && <p style={{ color: 'red' }}>{paymentError}</p>}
        {paymentSuccess && <p style={{ color: 'green' }}>{paymentSuccess}</p>}
      </div>
    );
}

export default SubscriptionComponent;
