import React, { useState, useEffect } from "react";
import TopNav from "../Components/TopNav";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { AddSubscription } from "../Redux/counterSlice";
import { EditSubs } from "../Redux/counterSlice";
import axios from "axios";

// import 'bootstrap/dist/css/bootstrap.min.css';

const AddSubscriptionadmin = () => {
  const [formId, setFormId] = useState("");
  const [SubscriptionTitle, setSubscriptionTitle] = useState("");
  const [SubscriptionCost, setSubscriptionCost] = useState("");
  const [summary, setSummary] = useState("");
  const [SubscriptionValidity, setSubscriptionValidity] = useState("");
  const [Highlighted, setHighlighted] = useState(false);

  // Redux data
  const AddSubs = useSelector((state) => state.counter.AddSubs);
  const editcasee_sub = useSelector((state) => state.counter.editcasee_sub);
  const AdminCred_id = useSelector((state) => state.counter.AdminCred);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleNavigate = (route) => {
    navigate(route);
    dispatch(EditSubs(false));
  };

  useEffect(() => {
    debugger;
    if (editcasee_sub) {
      if(AddSubs){
        const countcopy = [...AddSubs];
        const findexpIndex = AddSubs.findIndex((x) => x.id === editcasee_sub);
        if(findexpIndex !== -1)
        {
          setSubscriptionTitle(countcopy[findexpIndex].SubscriptionTitle);
          setSubscriptionCost(countcopy[findexpIndex].SubscriptionCost);
          setSubscriptionValidity(countcopy[findexpIndex].SubscriptionValidity);
          setSummary(countcopy[findexpIndex].summary);
          setHighlighted(countcopy[findexpIndex].Highlighted);
          setFormId(countcopy[findexpIndex].id); 
        }
      } 
    }
  }, [editcasee_sub]);

  function generateRandomId(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomId = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomId += characters.charAt(randomIndex);
    }

    return randomId;
  }
  const apiUrl = 'https://cloudresumes.com/api/add_subscription.php';
  const update = 'https://cloudresumes.com/api/update_subscriptions.php';

  const saveExpRecords = () => {
    debugger;
    const findexpIndex = AddSubs.findIndex((x) => x.id === editcasee_sub);
    if(findexpIndex === -1)
    {

      const payload = {
        admin_id: 1,
        admin_token:AdminCred_id.admin_token, 
        name: SubscriptionTitle,
        description: summary,
        price:+SubscriptionCost,
        duration: +SubscriptionValidity
    };
    
      axios.post(apiUrl, payload)
        .then(response => {
           handleNavigate("/Subscriptionadmin");

            console.log('Response from the server:', response.data);
        })
        .catch(error => {
            console.error('Error occurred:', error);
        });
    }
    else
    {
      const payload = {
        admin_id: 1,
        id: formId,
        admin_token:AdminCred_id.admin_token, 
        name: SubscriptionTitle,
        description: summary,
        price:+SubscriptionCost,
        duration: +SubscriptionValidity
    };
      axios.post(update ,payload)
        .then(response => {
          debugger
        handleNavigate("/Subscriptionadmin");

            console.log('Response from the server:', response.data);
        })
        .catch(error => {
            console.error('Error occurred:', error);
        });
    }

  }


  return (
    <div className="col-lg-10 p-0 rightSideSection">
      <TopNav MainHeading={"Update Subscription"} />
      {/* First row */}
      <div className="row text-start mb-3 m-2">
        <div className="col-lg-4">
          <label htmlFor="SubscriptionTitle">Subscription Title</label>
          <input
            type="text"
            className="form-control inputfield"
            id="SubscriptionTitle"
            placeholder="Subscription Title"
            value={SubscriptionTitle}
            onChange={(e) => setSubscriptionTitle(e.target.value)}
          />
        </div>
        <div className="col-lg-4 mt-3 mt-lg-0">
          <label htmlFor="SubscriptionCost">Subscription Cost</label>
          <input
            type="text"
            className="form-control inputfield"
            id="SubscriptionCost"
            placeholder="Subscription Cost"
            value={SubscriptionCost}
            onChange={(e) => setSubscriptionCost(e.target.value)}
          />
        </div>
        <div className="col-lg-4 mt-3 mt-lg-0">
          <label htmlFor="SubscriptionValidity">Subscription Validity</label>
          <input
            type="text"
            className="form-control inputfield"
            id="SubscriptionValidity"
            placeholder="Subscription Validity"
            value={SubscriptionValidity}
            onChange={(e) => setSubscriptionValidity(e.target.value)}
          />
        </div>
      </div>
      <div className="row text-start mb-3 m-2">
        <div className="col">
          <label htmlFor="summary">Summary</label>
          <textarea
            className="form-control inputfield h-100 mb-5"
            id="summary"
            placeholder="Summary"
            value={summary}
            onChange={(e) => setSummary(e.target.value)}
          ></textarea>
        </div>
      </div>
      <div class="d-flex justify-content-end m-3 my-5">
      <button
            class="btn btn-secondary bg-white border-primary text-primary w-25 me-3"
            onClick={() => handleNavigate("/Subscriptionadmin")}
          >
            Cancel
          </button>
      <button
            class="btn btn-primary w-25"
            onClick={saveExpRecords}
          >
            {`${editcasee_sub ? 'Update' : 'Add' }`}
          </button>
      </div>
      {/* Rest of your content here */}
    </div>
  );
};

export default AddSubscriptionadmin;
