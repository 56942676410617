import React, { useState, useEffect, useRef } from "react";
import { IoIosAddCircleOutline } from "react-icons/io";
import { MdEdit, MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { CerticateData } from "../Redux/counterSlice";
import { EditCertifications } from "../Redux/counterSlice";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import DatePicker from "react-datepicker";

import TopNav from "./TopNav";

const AddCetification = () => {
  const [formId, setFormId] = useState("");
  const [certTitle, setcertTitle] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const dispatch = useDispatch();
  const datePickerRef = useRef(null);

  const getcert_data = useSelector((state) => state.counter.certifcates);
  const GetEdit_certifcates = useSelector(
    (state) => state.counter.Edit_certifcates
  );
  useEffect(() => {
    
    if (GetEdit_certifcates) {
      const certificatID = [...getcert_data];
      const findexpIndex = certificatID.findIndex(
        (x) => x.id === GetEdit_certifcates
      );
      if(findexpIndex !== -1){
      setcertTitle(certificatID[findexpIndex].certTitle);
      setSelectedDate(certificatID[findexpIndex].selectedDate);
      setFormId(certificatID[findexpIndex].id);
      }
    }
  }, [GetEdit_certifcates]);

  function generateRandomId(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomId = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomId += characters.charAt(randomIndex);
    }

    return randomId;
  }

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}
  const handleChange = (date) => {
    if (date) {
      const inputDates = new Date(date);
        const formattedDates = formatDate(inputDates);
      const formattedDate = `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
      setSelectedDate(formattedDates);
      console.log(date); // Output: 'dd-mm-yyyy'
    } else {
      setSelectedDate(null);
      datePickerRef.current.setFocus();
    }
  };
  const CheckValidField = () => {
    if (certTitle === undefined || certTitle.trim() === "") {
      toast.error("Certifciation Title Cannot Be Empty");
      return false;
    }

    if (!selectedDate) {
      toast.error("Date Issue Cannot Be Empty");
      return false;
    }
    return true;
  };
  const saveExpRecords = () => {
    let array = [];
    const randomId = generateRandomId(certTitle.length);
    if (getcert_data) {
      array = [...getcert_data];
    }
    const checkEditCase = array.findIndex((x) => x.id === formId);
    if (checkEditCase === -1) {
      const obj = {
        id: randomId,
        certTitle: certTitle,
        selectedDate: selectedDate,
      };
      array.push(obj);
      dispatch(CerticateData(array));
      handleNavigate("/certification");
    } else {
      const obj = {
        id: randomId,
        certTitle: certTitle,
        selectedDate: selectedDate,
      };
      array[checkEditCase] = obj;
      dispatch(CerticateData(array));
      handleNavigate("/certification");
    }
  };
  const navigate = useNavigate();
  const handleNavigate = (route) => {
    navigate(route);
    dispatch(EditCertifications(false));
  };
  return (
    <div className="col-lg-10 p-0 rightSideSection">
      <ToastContainer />

      <TopNav MainHeading={"Add Certification"} />
      {/* First row */}
      <div className="componentContainer paddingGen shadow form-font">
        <div className="row text-start mb-5">
          <div className="col-lg-6">
            <label htmlFor="certTitle">Certification Title</label>
            <input
              type="text"
              className="form-control inputfield"
              id="certTitle"
              placeholder="Certifciation Title"
              value={certTitle}
              onChange={(e) => setcertTitle(e.target.value)}
            />
          </div>
          <div className="mt-3 mt-lg-0 col-lg-6">
            <label htmlFor="startMonth">Date Issue</label>
            <DatePicker
              showYearDropdown
              minDate={new Date(1950, 0, 1)}
              maxDate={new Date()}
              yearDropdownItemNumber={100}
              scrollableYearDropdown
              onChange={handleChange}
              value={selectedDate}
              dateFormat="dd-MM-yyyy"
              showTimeSelect={false} 
              className="form-control inputfield"
              id="SchoolLocation"
              placeholderText="Date Issue"
              ref={datePickerRef}
            />
          </div>
        </div>
        <div class="d-flex justify-content-end my-5">
          <button
            class="btn btn-secondary bg-white me-3 w-25"
            onClick={() => handleNavigate("/certification")}
          >
            Cancel
          </button>
          <button class="btn btn-primary w-25" onClick={saveExpRecords}>
            {`    `}Add
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddCetification;
